/**
 * Init
 *
 * @module FAQ
 */
export default function makeFAQ () {
    makeSearchLogic();
    makeFaqCardsEffects();
    makeMoreCardsLoader();
}

/**
 * Funkcja odpowiada za logikę wyszukiwarki.
 *
 * @memberof module:FAQ
 * @version 1.0.0
 */
function makeSearchLogic () {
    const $searchForm = $('#js-faq-search-form'),
        $searchInput = $('#js-faq-search-input'),
        $showMoreCardsLink = $('.js-faq-show-more-cards'),
        $noResultsAlert = $('#js-faqs-no-results-alert');

    if (0 < $searchForm.length) {
        $searchForm.on('submit', (event) => {
            event.preventDefault();

            const searchValue = $searchInput.val().toLowerCase();

            let visibleSectionsCount = 0;

            // Rozwijamy wszystkie pliki
            $showMoreCardsLink.hide();

            // Ukrywamy lub pokazujemy karty
            $('.js-faqs-content-card-wrapper').each(function () {
                const cardTitle = $(this)
                    .find('.js-faqs-content-card-title')
                    .html()
                    .toLowerCase();

                if (cardTitle.indexOf(searchValue) === -1) {
                    $(this).addClass('is-hidden');
                } else {
                    $(this).removeClass('is-hidden');
                }
            });

            // Ukrywamy lub pokazujemy całe sekcje
            $('.js-faqs-content-section').each(function () {
                let visibleCardsCount = 0;

                $(this)
                    .find('.js-faqs-content-card-wrapper')
                    .each(function () {
                        if (!$(this).hasClass('is-hidden')) {
                            visibleCardsCount++;
                        }
                    });

                if (visibleCardsCount === 0) {
                    $(this).hide();
                } else {
                    $(this).show();
                    visibleSectionsCount++;
                }
            });

            // Pokazujemy lub ukrywamy komunikat o kompletnym braku wyników
            if (visibleSectionsCount === 0) {
                $noResultsAlert.removeClass('is-hidden');
            } else {
                $noResultsAlert.addClass('is-hidden');
            }
        });
    }
}

/**
 * Funkcja dodaje do card FAQ klasę informującą, że card jest aktualnie otwarty (potrzebne do efektów CSS).
 *
 * @memberof module:FAQ
 * @version 1.0.0
 */
function makeFaqCardsEffects () {
    const $cardCollapse = $('.js-faq-card-collapse');

    if (0 < $cardCollapse.length) {
        $cardCollapse.on('show.bs.collapse', function () {
            const $card = $(this).parent();

            if ($card.hasClass('card')) {
                $card.addClass('is-opened');
            }
        });

        $cardCollapse.on('hide.bs.collapse', function () {
            const $card = $(this).parent();

            if ($card.hasClass('card')) {
                $card.removeClass('is-opened');
            }
        });
    }
}

/**
 * Funkcja odpowiada za pokazywanie większej ilości kart FAQ po wcisnięciu linku "Pokaż pozostałe pytania".
 *
 * @memberof module:FAQ
 * @version 1.0.0
 */
function makeMoreCardsLoader () {
    const $showMoreCardsLink = $('.js-faq-show-more-cards');

    if (0 < $showMoreCardsLink.length) {
        $showMoreCardsLink.on('click', function (event) {
            event.preventDefault();

            const categorySlug = $(this).data('category-slug');

            $(this).hide();

            $('.js-faqs-content-card-wrapper').each(function () {
                if ($(this).data('category-slug') === categorySlug) {
                    $(this).removeClass('is-hidden');
                }
            });
        });
    }
}
