import Cookies from 'js-cookie';

/**
 * Init dla dostępności.
 *
 * @module A11Y
 */
export default function makeA11Y () {
    makeContrast();
    makeFonts();
}

/**
 * Funkcja odpowiada za przełączanie trybów kontrastowych dla strony.
 *
 * @memberof module:A11Y
 * @version 1.0.0
 */
function makeContrast () {
    const $contrastChangeButton = $('.js-a11y-contrast-change-button'),
        actualCookie = Cookies.get('aquanet-a11y-contrast');

    // Ustawienie kontrastu na załadowanie strony
    if (typeof actualCookie !== 'undefined') {
        changeContrast(actualCookie);
    }

    // Desktop / mobile
    $contrastChangeButton.on('click', function (event) {
        event.preventDefault();

        const contrastMode = $(this).data('mode');

        changeContrast(contrastMode);
    });
}

/**
 * Funkcja odpowiada za zmianę trybu kontrastowego na całej stronie.
 *
 * @memberof module:A11Y
 * @param {string} contrastMode - tryb kontrastowy
 * @version 1.0.0
 */
function changeContrast (contrastMode) {
    let cookieValue = null;

    $('body')
        .removeClass('a11y-black-and-white')
        .removeClass('a11y-black-and-yellow')
        .removeClass('a11y-yellow-and-black');
    Cookies.remove('aquanet-a11y-contrast');

    switch (contrastMode) {
    case 'black-and-white':
        $('body').addClass('a11y-black-and-white');
        cookieValue = 'black-and-white';
        break;

    case 'black-and-yellow':
        $('body').addClass('a11y-black-and-yellow');
        cookieValue = 'black-and-yellow';
        break;

    case 'yellow-and-black':
        $('body').addClass('a11y-yellow-and-black');
        cookieValue = 'yellow-and-black';
        break;

    default:
        // Do nothing...
        break;
    }

    if (cookieValue !== null) {
        Cookies.set('aquanet-a11y-contrast', cookieValue, {
            expires: 1,
            secure: location.protocol === 'https:'
        });
    }
}

/**
 * Funkcja odpowiada za przełączanie wielkości font'a na całej stronie.
 * Teoretycznie wedle zaleceń font powinien zwiększyć się o 150% oraz 200% względem domyślnej wielkości.
 *
 * @memberof module:A11Y
 * @see https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-scale.html - WCAG dot. skalowania tekstów ("Increase text size to 200%").
 * @version 1.0.0
 */
function makeFonts () {
    const $fontSizeChangeButton = $('.js-a11y-font-size-change-button'),
        $fontSizeChangeButtonMobile = $('.js-a11y-font-size-change-button-mobile'),
        actualCookie = Cookies.get('aquanet-a11y-font');

    // Ustawienie font'u na załadowanie strony
    if (typeof actualCookie !== 'undefined') {
        changeFontSize(actualCookie);
    }

    // Desktop
    $fontSizeChangeButton.on('click', function (event) {
        event.preventDefault();

        const fontSize = $(this).data('font-size');

        $fontSizeChangeButton.removeClass('is-active');

        changeFontSize(fontSize);

        $(this).addClass('is-active');
    });

    // Mobile
    let actualMode = 0;

    $fontSizeChangeButtonMobile.on('click', function (event) {
        event.preventDefault();

        const modes = [
                'default',
                'medium',
                'large'
            ],
            changeDirection = $(this).attr('data-change');

        if (changeDirection === 'smaller') {
            if (0 <= actualMode - 1) {
                actualMode -= 1;
            }
        } else if (actualMode + 1 <= modes.length - 1) {
            actualMode += 1;
        }

        changeFontSize(modes[actualMode]);
    });
}

/**
 * Funkcja odpowiada za zmianę rozmiaru font'u na całej stronie.
 *
 * @memberof module:A11Y
 * @param {string} size - tryb (rozmiar fontu)
 * @version 1.0.0
 */
function changeFontSize (size) {
    let cookieValue = null;

    Cookies.remove('aquanet-a11y-font');

    switch (size) {
    case 'large':
        $('html').css('font-size', '24px'); // Teoretycznie tu powino być 32px (standard  WCAG)
        cookieValue = 'large';
        break;

    case 'medium':
        $('html').css('font-size', '20px'); // Teoretycznie tu powino być 22px (standard  WCAG)
        cookieValue = 'medium';
        break;

    case 'default':
    default:
        $('html').css('font-size', '16px');
        break;
    }

    if (cookieValue !== null) {
        Cookies.set('aquanet-a11y-font', cookieValue, {
            expires: 1,
            secure: location.protocol === 'https:'
        });
    }
}
