/* eslint max-lines: "off" */

/**
 * Init dla funkcji tworzących input'y.
 *
 * @module Inputs
 */
export default function makeInputs () {
    makeGlobalInputs();
    makeContact();
    makeFillableInputs();
    makeMaskedInputs();
    makeCalendar();
    makeInvalidFieldLogic();

    // Specyficzne formularze i ich logika
    makeBipForm();
    makeInsuranceForm();
}

/**
 * Funkcja odpowiada za init wszystkich maskowanych pól.
 *
 * @version 1.2.0
 */
function makeMaskedInputs () {
    const $inputPostalCode = $('.js-form-mask-postal-code'),
        $inputDate = $('.js-form-mask-date'),
        $inputBankAccount = $('.js-form-mask-bank-account'),
        $inputPhoneNumber = $('.js-form-mask-phone-number'),
        $inputPesel = $('.js-form-mask-pesel'),
        $inputRegon = $('.js-form-mask-regon'),
        $inputIdCard = $('.js-form-mask-id-card'),
        $inputNip = $('.js-form-mask-nip'),
        $inputKrs = $('.js-form-mask-krs'),
        $inputAgreementNumber = $('.js-form-mask-agreement-number'),
        $recipientsNumber = $('.js-form-mask-recipients-number'),
        $landRegisterBook = $('.js-land-register-book'),
        $constructionRightsNumber = $('.js-construction-rights-number'),
        $aqServiceAcceptNumber = $('.js-aq-service-accept-number'),
        $aqServicePreAcceptNumber = $('.js-aq-service-pre-accept-number');

    $inputPostalCode
        .mask('00-000')
        .attr({
            placeholder: '_ _ - _ _ _',
            minlength: 6,
            maxlength: 6,
            pattern: '.{6,6}'
        });

    $inputDate
        .mask('0000-00-00')
        .attr({
            placeholder: 'RRRR-MM-DD',
            autocomplete: 'off',
            minlength: 10,
            maxlength: 10,
            pattern: '.{10,10}'
        });

    $inputBankAccount
        .mask('00 0000 0000 0000 0000 0000 0000')
        .attr({
            placeholder: '00 0000 0000 0000 0000 0000 0000',
            minlength: 32,
            maxlength: 32,
            pattern: '.{32,32}'
        });

    $inputPhoneNumber
        .mask('(+00) 000 000 000')
        .attr({
            placeholder: '(+48) 000 000 000',
            minlength: 17,
            maxlength: 17,
            pattern: '.{17,17}'
        })
        .on('focus', function () {
            if ($(this).val() === '') {
                $(this).val('(+48) ');
            }
        })
        .on('focusout', function () {
            if ($(this).val() === '(+48) ') {
                $(this).val('');
            }
        });

    $inputPesel
        .mask('00000000000')
        .attr({
            placeholder: '00000000000',
            minlength: 11,
            maxlength: 11,
            pattern: '.{11,11}',
            title: 'Zawiera numer PESEL w formacie "000000000" (razem 11 znaków)'
        });

    $inputRegon
        .mask('000 000 000')
        .attr({
            placeholder: '000 000 000',
            minlength: 11,
            maxlength: 11,
            pattern: '.{11,11}',
            title: 'Zawiera numer REGON w formacie "000 000 000" (razem 11 znaków)'
        });

    $inputIdCard
        .mask('AAA000000', {
            translation: {
                // eslint-disable-next-line quote-props
                'A': {
                    pattern: /[A-Za-z]/u,
                    optional: false
                }
            }
        })
        .attr({
            placeholder: 'AAA000000',
            minlength: 9,
            maxlength: 9,
            pattern: '.{9,9}'
        });

    $inputNip
        .mask('0000000000')
        .attr({
            placeholder: '0000000000',
            minlength: 10,
            maxlength: 10,
            pattern: '.{10,10}',
            title: 'Zawiera numer NIP w formacie "0000000000" (razem 10 znaków)'
        });

    $inputKrs
        .mask('0000000000')
        .attr({
            placeholder: '0000000000',
            minlength: 10,
            maxlength: 10,
            pattern: '.{10,10}',
            title: 'Zawiera numer KRS w formacie "0000000000" (razem 10 znaków)'
        });

    $inputAgreementNumber
        .mask('AA/0000/000000/0000')
        .attr({
            placeholder: 'AA/0000/000000/0000',
            minlength: 19,
            maxlength: 19,
            pattern: '.{19,19}'
        });

    $recipientsNumber
        .mask('AAAA-AAA/AA')
        .attr({
            placeholder: 'AAAA-AAA/AA',
            minlength: 11,
            maxlength: 11,
            pattern: '.{11,11}'
        });

    $landRegisterBook
        .mask('AAAA/00000000/0')
        .attr({
            placeholder: 'AAAA/00000000/0',
            minlength: 15,
            maxlength: 15,
            pattern: '.{15,15}'
        });

    $constructionRightsNumber
        .mask('AAA/AA/0000/00')
        .attr({
            placeholder: 'AAA/AA/0000/00',
            minlength: 14,
            maxlength: 14,
            pattern: '.{14,14}'
        });

    $aqServiceAcceptNumber
        .mask('81F/0QQQ/0000', {
            translation: {
                // eslint-disable-next-line id-length
                F: {
                    pattern: 0,
                    optional: false
                },
                // eslint-disable-next-line id-length
                Q: {
                    pattern: /[0-9]/u,
                    optional: true
                }
            }
        })
        .attr({
            Placeholder: '810/AA/0000',
            Minlength: 10,
            Maxlength: 13
        })
        .on('focus', function () {
            if ($(this).val() === '') {
                $(this).val('810/');
            }
        })
        .on('focusout', function () {
            if ($(this).val() === '810/') {
                $(this).val('');
            }
        });

    $aqServicePreAcceptNumber
        .mask('82F/0QQ/0000', {
            translation: {
                // eslint-disable-next-line id-length
                F: {
                    pattern: 0,
                    optional: false
                },
                // eslint-disable-next-line id-length
                Q: {
                    pattern: /[0-9]/u,
                    optional: true
                }
            }
        })
        .attr({
            Placeholder: '820/AA/0000',
            Minlength: 10,
            Maxlength: 12
        })
        .on('focus', function () {
            if ($(this).val() === '') {
                $(this).val('820/');
            }
        })
        .on('focusout', function () {
            if ($(this).val() === '820/') {
                $(this).val('');
            }
        });
}

/**
 * Funkcja odpowiedzialna za zarządzanie polem kalendarza.
 *
 * @version 1.1.0
 */
function makeCalendar () {
    const date = new Date(),
        firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1),
        $formDatepicker = $('.js-form-datepicker'),
        $formDatepickerPast = $('.js-form-datepicker-past'),
        $formDatepickerFuture = $('.js-form-datepicker-future'),
        $formDatepickerMonthAndFuture = $('.js-form-datepicker-month-and-future'),
        $formDatepickerYear = $('.js-form-datepicker-year');

    $formDatepicker.datepicker({
        language: getDatepickerLangauge(),
        dateFormat: 'yyyy-mm-dd',
        autoClose: true,
        todayButton: date,
        clearButton: true
    }).attr('autocomplete', 'off');

    $formDatepickerPast.datepicker({
        language: getDatepickerLangauge(),
        dateFormat: 'yyyy-mm-dd',
        maxDate: date,
        autoClose: true,
        todayButton: date,
        clearButton: true
    }).attr('autocomplete', 'off');

    $formDatepickerFuture.datepicker({
        language: getDatepickerLangauge(),
        dateFormat: 'yyyy-mm-dd',
        minDate: date,
        autoClose: true,
        todayButton: date,
        clearButton: true
    }).attr('autocomplete', 'off');

    $formDatepickerMonthAndFuture.datepicker({
        language: getDatepickerLangauge(),
        dateFormat: 'yyyy-mm-dd',
        minDate: firstMonthDay,
        autoClose: true,
        todayButton: date,
        clearButton: true
    }).attr('autocomplete', 'off');

    $formDatepickerYear.datepicker({
        language: getDatepickerLangauge(),
        dateFormat: 'yyyy',
        autoClose: true,
        todayButton: date,
        clearButton: true,
        view: 'years',
        minView: 'years'
    }).attr('autocomplete', 'off');
}

/**
 * [...]
 *
 * @version 1.1.0
 */
function makeInsuranceForm () {
    const $insuranceSelector = $('#js-insurance-selector');

    $insuranceSelector.on('change', function () {
        const selectedValue = $(this).find(':selected').attr('data-value-code'),
            $insuranceBankingCollapse = $('#js-insurance-return-banking-account'),
            $insuranceOtherCollapse = $('#js-insurance-return-other');

        if (selectedValue === 'bank-account') {
            $insuranceOtherCollapse.collapse('hide');

            setTimeout(() => {
                $insuranceBankingCollapse.collapse('show');
            }, 250);
        } else {
            $insuranceBankingCollapse.collapse('hide');

            setTimeout(() => {
                $insuranceOtherCollapse.collapse('show');
            }, 250);
        }
    });
}

/**
 * Funkcja zarządza otwieraniem collapse z danymi adresowymi na formularzu BIP.
 *
 * @version 1.0.0
 */
function makeBipForm () {
    const $shippingSelector = $('#js-bip-shipping-selector');

    $shippingSelector.on('change', function () {
        const selectedValue = $(this).val(),
            $inputClientNumberCollapse = $('#js-collapse-form-bip-city');

        if (selectedValue === 'home-address' || selectedValue === 'shipping-by-post-photocopy' || selectedValue === 'shipping-by-post-cd') {
            $inputClientNumberCollapse.collapse('show');
        } else {
            $inputClientNumberCollapse.collapse('hide');
        }
    });
}

/**
 * Funkcja tworzy globalne input'y.
 *
 * @memberof module:Inputs
 * @version 1.0.0
 */
function makeGlobalInputs () {
    $('.js-default-selector').each(function () {
        let options = {};

        if ($(this).attr('placeholder')) {
            options = {
                minimumResultsForSearch: -1,
                theme: 'aquanet',
                width: 'resolve',
                placeholder: $(this).attr('placeholder')
            };
        } else {
            options = {
                minimumResultsForSearch: -1,
                theme: 'aquanet',
                width: 'resolve'
            };
        }

        $(this).select2(options);
    });

    $('.js-search-selector').each(function () {
        let options = {};

        if ($(this).attr('placeholder')) {
            options = {
                theme: 'aquanet',
                width: 'resolve',
                placeholder: $(this).attr('placeholder')
            };
        } else {
            options = {
                theme: 'aquanet',
                width: 'resolve'
            };
        }

        $(this).select2(options);
    });

    $('.js-gradient-selector').each(function () {
        let options = {};

        if ($(this).attr('placeholder')) {
            options = {
                minimumResultsForSearch: -1,
                theme: 'aquanet-gradient',
                width: 'resolve',
                placeholder: $(this).attr('placeholder')
            };
        } else {
            options = {
                minimumResultsForSearch: -1,
                theme: 'aquanet-gradient',
                width: 'resolve'
            };
        }

        $(this).select2(options);
    });
}

/**
 * Zarządzanie działaniem pól z możliwością czyszczenia na 'X'.
 *
 * @memberof module:Inputs
 * @version 1.0.0
 */
function makeFillableInputs () {
    $('.js-input-fillable').each(function () {
        helperContactInputs($(this));
    });
    $('.js-input-fillable').on('focusout', function () {
        helperContactInputs($(this));
    });

    /**
     * [...]
     *
     * @memberof module:Inputs
     * @param {object} $element [...]
     */
    function helperContactInputs ($element) {
        const value = $element.val();

        if (value.length === 0) {
            $element.removeClass('is-not-empty');
        } else {
            $element.addClass('is-not-empty');
        }
    }
}

/**
 * Funkcja zarządza logiką pól na stronie kontaktowej.
 *
 * @memberof module:Inputs
 * @version 1.1.0
 */
function makeContact () {
    $('#js-contact-collapse-additional-info').on('click', function (event) {
        event.preventDefault();

        const $collapseContent = $('#js-contact-collapse-additional-info-content');

        let contentHeight = 0;

        $collapseContent.children().each(function () {
            contentHeight += $(this).outerHeight();
        });

        if ($(this).hasClass('is-open') === false) {
            $(this).addClass('is-open');
            $collapseContent.css('height', contentHeight);

            setTimeout(() => {
                $collapseContent.addClass('is-open');
            }, 500);
        } else {
            $(this).removeClass('is-open');
            $collapseContent.css('height', '');
            $collapseContent.removeClass('is-open');
        }
    });

    $('.js-contact-company-client').on('change', function () {
        const selectedValue = $(this).val(),
            $inputClientNumberCollapse = $('#js-contact-client-number-collapse'),
            $clientNumberField = $('.js-contact-client-number'),
            $clientNumberFieldRequiredStatus = typeof $clientNumberField.attr('data-is-required') !== 'undefined';

        if (selectedValue === '1') {
            $inputClientNumberCollapse.collapse('show');

            if ($clientNumberFieldRequiredStatus === true) {
                $clientNumberField
                    .prop('required', true)
                    .val(null); // QuickFix: Obejście back-end'u
            }
        } else {
            $inputClientNumberCollapse.collapse('hide');

            if ($clientNumberFieldRequiredStatus === true) {
                $clientNumberField
                    .prop('required', false)
                    .val(' '); // QuickFix: Obejście back-end'u
            }
        }
    });
}

/**
 * Funkcja zwracająca konfigurację językową dla Air Datepicker.
 *
 * @return {object} - array zawierający konfigurację językową.
 * @version 1.0.0
 */
function getDatepickerLangauge () {
    return {
        days: [
            'Niedziela',
            'Poniedziałek',
            'Wtorek',
            'Środa',
            'Czwartek',
            'Piątek',
            'Sobota'
        ],
        daysShort: [
            'Nie',
            'Pon',
            'Wto',
            'Śro',
            'Czw',
            'Pią',
            'Sob'
        ],
        daysMin: [
            'Nd',
            'Pn',
            'Wt',
            'Śr',
            'Czw',
            'Pt',
            'So'
        ],
        months: [
            'Styczeń',
            'Luty',
            'Marzec',
            'Kwiecień',
            'Maj',
            'Czerwiec',
            'Lipiec',
            'Sierpień',
            'Wrzesień',
            'Październik',
            'Listopad',
            'Grudzień'
        ],
        monthsShort: [
            'Sty',
            'Lut',
            'Mar',
            'Kwi',
            'Maj',
            'Cze',
            'Lip',
            'Sie',
            'Wrz',
            'Paź',
            'Lis',
            'Gru'
        ],
        today: 'Dzisiaj',
        clear: 'Wyczyść',
        dateFormat: 'yyyy-mm-dd',
        timeFormat: 'hh:ii:aa',
        firstDay: 1
    };
}

/**
 * Funkcja zarządza custom scroll do błędnego elementu w formularzu.
 *
 * @version 1.4.0
 */
function makeInvalidFieldLogic () {
    $('input, textarea').on('invalid', () => {
        // Height of your nav bar plus a bottom margin
        const additionalOffset = 150,
            navHeight = $('#js-header-menubar').innerHeight() + additionalOffset,
            // Offset of the first input element minus your nav height
            invalidElement = $('input:invalid, textarea:invalid'),
            invalidElementPosition = (typeof invalidElement.offset() !== 'undefined') ? invalidElement.offset().top - navHeight : null;

        invalidElement.addClass('is-invalid');

        // If the invalid element is already within the window view, return true. If you return false, the validation will stop.
        if (invalidElementPosition > window.pageYOffset - navHeight && invalidElementPosition < window.pageYOffset + window.innerHeight - navHeight) {
            return true;
        }
        // If the first invalid input is not within the current view, scroll to it.
        $('html, body').scrollTop(invalidElementPosition);

        return true;
    });

    // Zdejmowanie klasy na edycji
    $('input, textarea').on('change keyup', function () {
        $(this).removeClass('is-invalid');

        // Dodatkowe zdejmowanie klasy dla wszystkich pól checkbox typu "Wybierz minimum jedną opcję"
        // (trochę jak logika radio, ale to jednak jest checkbox).
        if ($(this).hasClass('js-checkbox-one-from-all')) {
            $('.js-checkbox-one-from-all').removeClass('is-invalid');
        }
        if ($(this).hasClass('js-checkbox-one-from-all-documents')) {
            $('.js-checkbox-one-from-all-documents').removeClass('is-invalid');
        }
        if ($(this).hasClass('js-checkbox-one-from-all-attachments')) {
            $('.js-checkbox-one-from-all-attachments').removeClass('is-invalid');
        }

        // Zdejmowanie klasy dla radio, które odpowiada temu samemu wyborowi.
        const thisName = $(this).attr('name');

        $('input[type="radio"]').each(function () {
            if ($(this).attr('name') === thisName) {
                $(this).removeClass('is-invalid');
            }
        });
    });
}
