/**
 * Init dla logiki kariery.
 *
 * @module Kariera
 * @version 1.0.0
 */
export default function makeCareerLogic () {
    // Trigger - desktop
    $('.js-career-offer-type-switcher').on('click', function (event) {
        event.preventDefault();

        const offersType = $(this).data('type');

        switchOffers(offersType);
    });

    // Trigger - mobile
    $('#js-career-type-selector').on('select2:select', function (event) {
        event.preventDefault();

        const offersType = $(this).val();

        switchOffers(offersType);
    });
}

/**
 * Funkcja przełącza widoczność ofert w zależności od aktualnie wybranego typu.
 *
 * @memberof module:Kariera
 * @param {string} type - typ ofert (all / work / training / other-company)
 * @example switchOffers('all');
 * @version 1.1.0
 */
function switchOffers (type) {
    $('.js-career-offer-item').removeClass('is-inactive');

    switch (type) {
    case 'all':
        $('.js-career-offer-item').removeClass('is-inactive');
        break;
    default:
        $('.js-career-offer-item').each(function () {
            if ($(this).data('type') !== type) {
                $(this).addClass('is-inactive');
            }
        });
        break;
    }
}
