/**
 * Init.
 */
export default function makeForm () {
    removeFormLock();
}

/**
 * Funkcja odpowiada za wyłączenie blokady na formularzu po załadowaniu strony.
 *
 * @version 1.0.0
 */
function removeFormLock () {
    const $formContent = $('.js-form-content');

    $formContent.removeClass('is-locked');
}
