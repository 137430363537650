import Cookies from 'js-cookie';

/**
 * Init
 *
 * @module CookieInfo
 */
export default function makeCookies () {
    makeCookieManager();
    makeDetailsCollapse();
    verifyCookies();
    makeCookieLink();
}

/**
 * Funkcja zarządza logiką cookies.
 *
 * @memberof module:CookieInfo
 * @version 1.0.0
 */
function makeCookieManager () {
    const $cookieBar = $('#js-cookie-info'),
        $cookieBarAccept = $cookieBar.find('.js-accept-cookies'),
        $cookieBarDecline = $cookieBar.find('#js-decline-cookies'),
        $cookieBarCheckboxStatistics = $cookieBar.find('input[name="cookies_statistics"]'),
        $body = $('body');

    if (0 < $cookieBar.length) {
        if (typeof Cookies.get('aquanet-cookies-accepted') === 'undefined') {
            $cookieBar.removeClass('hide');
            $body.addClass('is-locked');
        }

        // Użytkownik akceptuje Cookie
        $cookieBarAccept.on('click', function () {
            event.preventDefault();

            const acceptAll = $(this).attr('data-accept-all') === 'true',
                cookiesList = {
                    cookiesRequired: true,
                    cookiesStatistics: acceptAll === true ? true : $cookieBarCheckboxStatistics.prop('checked')
                };

            Cookies.set('aquanet-cookies-accepted', btoa(JSON.stringify(cookiesList)), {
                expires: 31,
                secure: location.protocol === 'https:'
            });

            $cookieBar.addClass('hide');
            $body.removeClass('is-locked');

            verifyCookies();
        });

        // Użytkownik anuluje Cookie
        $cookieBarDecline.on('click', (event) => {
            event.preventDefault();

            $cookieBar.addClass('hide');
            $body.removeClass('is-locked');
        });
    }
}

/**
 * Funkcja odpowiada za zweryfikowanie jakie ciastka są wybrane, a następnie
 * załadowanie lub odrzucenie danego stanu rzeczy wedle preferencji użytkownika.
 *
 * @memberof module:CookieInfo
 * @version 1.0.0
 */
function verifyCookies () {
    if (typeof Cookies.get('aquanet-cookies-accepted') !== 'undefined') {
        try {
            const cookiesList = JSON.parse(atob(Cookies.get('aquanet-cookies-accepted')));

            // Ładujemy skrypty pod statystykę
            if (cookiesList.cookiesStatistics === true) {
                $('head').append(`
                    <!-- Global site tag (gtag.js) - Google Analytics -->
                    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-123215131-1"></script>
                    <script>
                        window.dataLayer = window.dataLayer || [];

                        function gtag() {
                            dataLayer.push(arguments);
                        }

                        gtag('js', new Date());
                        gtag('config', 'UA-123215131-1');
                    </script>
                `);
            }
        } catch (error) {
            console.error('Nieprawidłowa zawartość pliku cookies!');
        }
    }
}

/**
 * Funkcja odpowiada za logikę rozwijania szczegółów ciasteczek.
 *
 * @memberof module:CookieInfo
 * @version 1.0.0
 */
function makeDetailsCollapse () {
    const $collapseLink = $('#js-cookie-manager-details-link'),
        $collapse = $('#js-cookie-manager-details');

    $collapse.on('show.bs.collapse', () => {
        $collapseLink.html($collapseLink.data('text-close'));
    });

    $collapse.on('hide.bs.collapse', () => {
        $collapseLink.html($collapseLink.data('text-open'));
    });
}

/**
 * Funkcja zarządzająca logiką linku wyświetlającego belkę cookies.
 *
 * @version 1.0.0
 */
function makeCookieLink () {
    const $cookieOpenBar = $('.js-cookie-info-open-bar'),
        $cookieBar = $('#js-cookie-info');

    $cookieOpenBar.on('click', function (event) {
        event.preventDefault();

        $cookieBar.removeClass('hide');
    });
}
