/**
 * Init dla funkcji związanych z aktualnościami.
 *
 * @module Aktualnosci
 */
export default function makeNewsLogic () {
    itemLogic();
}

/**
 * Funkcja zarządza logiką działania collapse dla aktualności.
 *
 * @memberof module:Aktualnosci
 * @version 1.0.0
 */
function itemLogic () {
    const $newsContent = $('#js-news-items');

    $newsContent.on('click', '.js-item-news-collapse', function (event) {
        event.preventDefault();

        const $this = $(this),
            $parent = $this.parent(),
            $label = $parent.find('.js-item-news-collapse-text'),
            $contentBox = $parent.find('.js-item-news-content-box');

        let contentHeight = 0;

        $contentBox.children().each(function () {
            contentHeight += $(this).outerHeight();
        });

        if ($parent.hasClass('is-active') === false) {
            // Otwieramy tą aktualność
            $parent.addClass('is-active');
            $label.html($label.data('text-close'));
            $contentBox.css('height', contentHeight);

            setTimeout(() => {
                $contentBox.css('height', 'auto');
            }, 500);
        } else {
            // Zamykamy tą aktualność
            $contentBox.css('height', contentHeight);
            $parent.removeClass('is-active');
            $label.html($label.data('text-open'));

            setTimeout(() => {
                $contentBox.css('height', '');
            }, 10);
        }
    });
}
