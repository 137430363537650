/**
 * Init
 *
 * @module Przetargi
 */
export default function makeAuctions () {
    makeMobileSelectLogic();
}

/**
 * Funkcja odpowiada za przełączanie kart z danymi w zależności od wybranej kategorii przetargów.
 *
 * @memberof module:Przetargi
 * @version 1.0.0
 */
function makeMobileSelectLogic () {
    const $auctionTypeSelect = $('#js-auction-category-selector');

    if (0 < $auctionTypeSelect.length) {
        $auctionTypeSelect.on('select2:select', function () {
            $('#pills-auctions-tab a[href="#pills-' + $(this).val() + '"]').tab('show');    // eslint-disable-line
        });
    }
}
