/* global L, mda_theme_url */

/**
 * Init
 *
 * @module Inwestycja
 */
export default function makeInvestmentLogic () {
    galleryLinkLogic();
    galleryImagesLogic();
    makeMap();
}

/**
 * Funkcja odpowiada za zmianę tekstu na linku "Zobacz więcej zdjęć" po rozwinięciu zdjęć.
 *
 * @memberof module:Inwestycja
 * @version 1.0.0
 */
function galleryLinkLogic () {
    $('#js-investment-gallery-collapse').on('show.bs.collapse', () => {
        const $link = $('#js-investment-gallery-link');

        $link.find('#js-investment-gallery-link-text').html($link.data('close'));
    });

    $('#js-investment-gallery-collapse').on('hide.bs.collapse', () => {
        const $link = $('#js-investment-gallery-link');

        $link.find('#js-investment-gallery-link-text').html($link.data('open'));
    });
}

/**
 * Funkcja odpowiada za wyciemnianie wszystkich zdjęć poza aktualnie hover'owanym.
 *
 * @memberof module:Inwestycja
 * @version 1.0.0
 */
function galleryImagesLogic () {
    const $investmentGalleryImageBox = $('.js-investment-gallery-image-box');

    $investmentGalleryImageBox.on('mouseenter', function () {
        const $hoveredImage = $(this);

        $investmentGalleryImageBox.each(function () {
            if ($(this)[0] !== $hoveredImage[0]) {
                $(this).addClass('is-not-hovered');
            }
        });
    });

    $investmentGalleryImageBox.on('mouseout', () => {
        $investmentGalleryImageBox.removeClass('is-not-hovered');
    });
}

/**
 * Funkcja odpowiada za obsługę interaktywnej mapy na inwestycji.
 *
 * @memberof module:Inwestycja
 * @version 1.0.0
 */
function makeMap () {
    const $mapDiv = $('#js-leaflet-investment-map');

    if (0 < $mapDiv.length) {
        const coords = JSON.parse($mapDiv.attr('data-points')),
            mapObject = L.map('js-leaflet-investment-map').setView([52.406263, 16.925107], 16), // eslint-disable-line
            markersGroup = L.markerClusterGroup();

        // Ładowanie tła mapy
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '<a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a>',
            tileSize: 256
        }).addTo(mapObject);

        coords.forEach((element) => {
            if (element.coordinates.length === 1) {
                // Tworzenie markera
                const markerIcon = L.icon({
                        iconUrl: `${mda_theme_url}/dist/img/leaflet/marker-default.png`, // eslint-disable-line camelcase
                        iconSize: [24, 24],     // eslint-disable-line
                        iconAnchor: [12, 24],   // eslint-disable-line
                        popupAnchor: [0, -24]   // eslint-disable-line
                    }),
                    marker = L.marker([
                        element.coordinates[0].lat,
                        element.coordinates[0].lng
                    ], {icon: markerIcon});

                marker.addTo(mapObject);
                markersGroup.addLayer(marker);
            } else if (1 < element.coordinates.length) {
                // Tworzenie obszaru
                const polygon = L.polygon(element.coordinates, { // eslint-disable-line
                    color: '#00aeef'
                }).addTo(mapObject);

                markersGroup.addLayer(polygon);
            } else {
                console.error(`Nieprawidłowe koordynaty dla punktu ${element.id}`);
            }
        });

        const markersGroupBounds = markersGroup.getBounds(); // eslint-disable-line one-var

        if (0 < Object.keys(markersGroupBounds).length) {
            mapObject.fitBounds(markersGroupBounds);
        }
    }
}
