/**
 * Init
 *
 * @module Projektanci
 */
export default function makeDesigners () {
    makeFilesExpandLogic();
    makeFilesSearchLogic();
}

/**
 * Funkcja odpowiada za logikę wyszukiwarki.
 *
 * @version 1.0.0
 */
function makeFilesSearchLogic () {
    const $searchForm = $('#js-designers-search-form'),
        $searchInput = $('#js-designers-search-input'),
        $showMoreCardsLink = $('.js-designers-files-collapse-link'),
        $noResultsAlert = $('#js-designers-no-results-alert');

    if (0 < $searchForm.length) {
        $searchForm.on('submit', (event) => {
            event.preventDefault();

            const searchValue = $searchInput.val().toLowerCase();

            let visibleSectionsCount = 0;

            // Rozwijamy wszystkie pliki
            $showMoreCardsLink.hide();
            $('.js-designers-file-wrapper').removeClass('is-nothing');
            $('.js-designers-files-collapse').collapse('show');

            // Ukrywamy lub pokazujemy karty
            $('.js-designers-file-wrapper').each(function () {
                const cardTitle = $(this)
                    .find('.js-designers-file-title')
                    .html()
                    .toLowerCase()
                    .replace('&nbsp;', ' ');

                if (cardTitle.indexOf(searchValue) === -1) {
                    $(this).addClass('is-hidden is-nothing');
                } else {
                    $(this).removeClass('is-hidden is-nothing');
                }
            });

            // Ukrywamy lub pokazujemy całe sekcje
            $('.js-designers-files-section').each(function () {
                const $filesSection = $(this),
                    $filesSectionId = $filesSection.attr('id');

                let visibleCardsCount = 0;

                $(this)
                    .find('.js-designers-file-wrapper')
                    .each(function () {
                        if (!$(this).hasClass('is-hidden')) {
                            visibleCardsCount++;
                        }
                    });

                if (visibleCardsCount === 0) {
                    $(this).hide();
                    $('.js-designers-header-item, .js-designers-menubar-box-link').each(function () {
                        if ($(this).attr('href').replace('#', '') === $filesSectionId) {
                            $(this).addClass('is-inactive');
                        }
                    });
                } else {
                    $(this).show();
                    $('.js-designers-header-item, .js-designers-menubar-box-link').each(function () {
                        if ($(this).attr('href').replace('#', '') === $filesSectionId) {
                            $(this).removeClass('is-inactive');
                        }
                    });
                    visibleSectionsCount++;
                }
            });

            // Pokazujemy lub ukrywamy komunikat o kompletnym braku wyników
            if (visibleSectionsCount === 0) {
                $noResultsAlert.removeClass('is-hidden');
            } else {
                $noResultsAlert.addClass('is-hidden');
            }
        });
    }
}

/**
 * Funkcja odpowiada za usunięcie przycisku "Pokaż wszystkie" po rozwinięciu collapse,
 * a także zarządza animacją wyświetlania kolejnych plików.
 *
 * @version 1.0.0
 */
function makeFilesExpandLogic () {
    const $collapse = $('.js-designers-files-collapse');

    if (0 < $collapse.length) {
        $collapse.on('show.bs.collapse', function () {
            $(this).find('.js-designers-file-wrapper').removeClass('is-nothing');
        });

        $collapse.on('shown.bs.collapse', function () {
            const collapseID = $(this).attr('id'),
                $thisCollapse = $(this);

            $thisCollapse.find('.js-designers-file-wrapper').each(function () {
                $(this).removeClass('is-hidden');
            });

            $('.js-designers-files-collapse-link').each(function () {
                const linkHyperlink = $(this).attr('href');

                if (linkHyperlink.replace('#', '') === collapseID) {
                    $(this).hide();
                }
            });
        });
    }
}
