import {getUrlParameter} from './accidents';
import {refreshNavigation} from './sliding-navigation';

/**
 * Init
 *
 * @module Cennik
 */
export default function makePricing () {
    makeUberCategorySwitch();
    makeCategorySwitch();

    $(window).on('load', () => {
        selectCategory();
    });
}

/**
 * Funkcja odpowiada za przełączenie karty na cenniku desktop.
 *
 * @memberof module:Cennik
 * @version 1.0.0
 */
function makeUberCategorySwitch () {
    const $menuCollapse = $('.js-pricing-menubox-collapse');

    if (0 < $menuCollapse.length) {
        $menuCollapse.on('show.bs.collapse', function () {
            const pricingContentId = $(this).attr('data-pricing-content'),
                $tab = $(`#pills-${pricingContentId}-tab`);

            $tab.tab('show');
        });

        $menuCollapse.on('shown.bs.collapse', () => {
            refreshNavigation();
        });
    }
}

/**
 * Funkcja odpowiada za przełączenie karty na mobilnym cenniku.
 *
 * @memberof module:Cennik
 * @version 1.0.0
 */
function makeCategorySwitch () {
    const $select = $('.js-select-pricing-category');

    if (0 < $select.length) {
        $select.on('select2:select', function () {
            const pricingContentId = $(this).val(),
                $tab = $(`#pills-mobile-${pricingContentId}-tab`);

            $tab.tab('show');
        });
    }
}

/**
 * Funkcja odpowiada za wybranie odpowiedniej kategorii w przypadku, jeżeli kategoria
 * zostanie przesłana w linku jako GET.
 *
 * @memberof module:Cennik
 * @version 1.0.0
 */
function selectCategory () {
    const category = getUrlParameter('category'),
        $menubarCollapse = $('.js-pricing-menubox-collapse');

    if (category !== false) {
        $menubarCollapse.each(function () { // eslint-disable-line consistent-return
            if ($(this).attr('data-section-id') === category) {
                $(this).collapse('show');

                return true;
            }
        });
    }
}
