import Swal from 'sweetalert2';
import {refreshMapPoints} from './../accidents';
import {refreshNavigation} from './../sliding-navigation';

let offset = 0;
const $section = $('#js-section-wip-and-accidents');
/* global ajax */

/**
 * Init.
 *
 * @version 1.0.0
 */
export default function ajaxGetAccidents () {
    const $loadMoreAccidentsButton = $section.find('#js-load-more-accidents'),
        $form = $section.find('#js-accident-form'),
        $switchCategoryButton = $section.find('.js-switch-accident-category-button'),
        $switchCategorySelect = $section.find('#js-wip-and-accident-category-selector'),
        $inputCategory = $section.find('#js-accidents-input-category'),
        $searchInput = $section.find('#js-accident-search-input'),
        $accidentSearchInputMobile = $section.find('#js-accident-search-input-mobile'),
        $accidentSearchButtonMobile = $section.find('#js-accident-search-button-mobile'),
        $mobileSearchContainer = $('#js-accident-mobile-search');

    // ======================
    // = Triggery Event'ów
    // ======================

    // Zmiana kategorii - desktop
    $switchCategoryButton.on('click', function (event) {
        event.preventDefault();

        const category = $(this).data('category');

        $inputCategory.val(category);
        $form.trigger('submit');
    });

    // Zmiana kategorii - mobile
    $switchCategorySelect.on('select2:select', function () {
        const category = $(this).val();

        $inputCategory.val(category);
        $form.trigger('submit');
    });

    // Doładowanie więcej wyników
    $loadMoreAccidentsButton.on('click', (event) => {
        event.preventDefault();

        loadAccidents(false);
    });

    // Trigger dla wysłania formularza
    $form.on('submit', (event) => {
        event.preventDefault();

        loadAccidents(true);
    });

    // Trigger wysyłający formularz jeśli na starcie jest wartość w polu wyszukiwarki
    if ($searchInput.val() !== '') {
        $form.trigger('submit');
    }

    // Trigger dla wyszukiwania mobile
    $accidentSearchButtonMobile.on('click', () => {
        $searchInput.val($accidentSearchInputMobile.val());
        $mobileSearchContainer.removeClass('is-visible');
        $form.trigger('submit');
    });
}

/**
 * Funkcja pobierająca zdarzenia i awarie.
 *
 * @param {boolean} makeClear - flaga, dzięki której wiadomo, czy należy wyczyścić aktualną paginację/numerację/offset.
 */
function loadAccidents (makeClear) {
    const $titlesBox = $('#js-section-wip-and-accidents-items-box'),
        $loadMoreAccidentsButton = $section.find('#js-load-more-accidents'),
        $form = $section.find('#js-accident-form'),
        $inputMapPoints = $section.find('#js-accidents-input-map-points'),
        $mapCollapse = $section.find('#js-section-wip-and-accidents-map-collapse'),
        formData = $form.serializeArray().reduce((obj, item) => {
            obj[item.name] = item.value;

            return obj;
        }, {}),
        $itemsContainer = $section.find('#js-section-wip-and-accidents-items');

    if (0 < $itemsContainer.length) {
        $.ajax({
            url: ajax.url,
            type: 'POST',
            data: {
                action: 'ajax_get_accidents',
                security: ajax.nonce,
                data: {
                    offset: offset,
                    category: formData.category,
                    numberOfPosts: formData.number_of_posts,
                    searchAddress: formData.search_address,
                    makeClear: makeClear
                }
            },
            beforeSend () {
                $section.addClass('is-loading');
            },
            success (rawResponse) {
                const response = rawResponse.data,
                    $numberOfAccidentsSpan = $('#js-number-of-accidents'),
                    $numberOfPlannedWorkSpan = $('#js-number-of-planned-works'),
                    $numberOfImpedimentsSpan = $('#js-number-of-impediments');

                if (makeClear === true) {
                    $itemsContainer.html(response.html);
                } else {
                    $itemsContainer.append(response.html);
                }

                // Jeżeli brak wyników, to na potrzeby wyglądu ukrywamy link "Pokaż więcej" i belkę z tytułami oraz zamykamy mapę
                if (response.is_empty === true) {
                    $loadMoreAccidentsButton.hide();
                    $titlesBox.hide();
                    $mapCollapse.collapse('hide');
                } else {
                    $loadMoreAccidentsButton.show();
                    $titlesBox.show();
                }

                // Jeżeli nie ma więcej wyników do doładowania, to ukrywamy link "Pokaż więcej"
                if (response.has_more === true) {
                    $loadMoreAccidentsButton.show();
                } else {
                    $loadMoreAccidentsButton.hide();
                }

                // Jeżeli wyszukiwaliśmy to pokazujemy ilość wyszukanych wyników
                if (typeof response.number_of_results === 'object') {
                    if (typeof response.number_of_results.accident === 'number') {
                        $numberOfAccidentsSpan.html(`(${response.number_of_results.accident})`);
                    }

                    if (typeof response.number_of_results.planned_work === 'number') {
                        $numberOfPlannedWorkSpan.html(`(${response.number_of_results.planned_work})`);
                    }

                    if (typeof response.number_of_results.impediment === 'number') {
                        $numberOfImpedimentsSpan.html(`(${response.number_of_results.impediment})`);
                    }
                } else {
                    $numberOfAccidentsSpan.html('');
                    $numberOfPlannedWorkSpan.html('');
                    $numberOfImpedimentsSpan.html('');
                }

                // Nadpisanie offset'u
                offset = response.offset; // eslint-disable-line prefer-destructuring

                // Nadpisanie wartości dla punktów na mapie
                $inputMapPoints.val(response.map_points);

                refreshMapPoints();
                refreshNavigation();
                $section.removeClass('is-loading');
            },
            error (xhr, error) {
                console.error(error);

                const response = JSON.parse(xhr.responseText);

                Swal.fire(
                    'Błąd',
                    response.data.message,
                    'error'
                );
            }
        });
    }
}

/**
 * Funkcja zwracająca kod HTML z belką jednego incydentu.
 *
 * @param {int} accidentId - id incydentu, który ma zostać zwrócony.
 * @returns [...]
 * @version 1.0.0
 */
export function getAccident (accidentId) {
    let response = null;

    $.ajax({
        url: ajax.url,
        type: 'POST',
        async: false,
        data: {
            action: 'ajax_get_accident',
            security: ajax.nonce,
            data: {
                accidentId: accidentId
            }
        },
        success (rawResponse) {
            response = rawResponse.data;
        },
        error (xhr, error) {
            console.error(error);

            response = JSON.parse(xhr.responseText);

            Swal.fire(
                'Błąd',
                response.data.message,
                'error'
            );
        }
    });

    return response;
}
