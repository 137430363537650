import {getAccident} from './ajax/get-accidents';

/* global L, mda_theme_url */
let mapObject = null,
    markersGroup = null;
const $section = $('#js-section-wip-and-accidents'),
    $accidentsItemWrapper = $('#js-leaflet-accidents-map-accident-item-wrapper');

/**
 * Init
 *
 * @module Awarie
 */
export default function makeAccidents () {
    makeMapLogic();
    makeMobileSearch();

    $(window).on('load', () => {
        selectCategory();
    });
}

/**
 * Funkcja odpowiada za logikę otwierania i ładowania mapy, a także logikę samej mapy.
 *
 * @memberof module:Awarie
 * @version 1.0.0
 */
function makeMapLogic () {
    const $mapCollapse = $section.find('#js-section-wip-and-accidents-map-collapse'),
        $mapCollapseLinkText = $section.find('#js-section-wip-and-accidents-map-collapse-text'),
        $mapBox = $section.find('#js-section-wip-and-accidents-map-box');

    // Zmianiamy tekst na przycisku
    $mapCollapse.on('show.bs.collapse', () => {
        $mapCollapseLinkText.html($mapCollapseLinkText.data('hide'));
    });

    // Ładujemy i pokazujemy mapę
    $mapCollapse.on('shown.bs.collapse', () => {
        // Inicjujemy mapę jeżeli jeszcze nie została zainicjalizowana
        if (mapObject === null) {
            makeMap();
        }

        // Pokazujemy mapę
        $mapBox.addClass('is-visible');
    });

    // Zamykamy mapę (ale bez zabijania mapy, bo nie ma sensu jej zabijać)
    $mapCollapse.on('hide.bs.collapse', () => {
        $mapCollapseLinkText.html($mapCollapseLinkText.data('show'));
        $mapBox.removeClass('is-visible');
    });
}

/**
 * Funkcja odpowiada za obsługę interaktywnej mapy na awariach.
 *
 * @memberof module:Awarie
 * @version 1.0.0
 */
function makeMap () {
    const $mapDiv = $('#js-leaflet-accidents-map');

    if (0 < $mapDiv.length) {
        mapObject = L.map('js-leaflet-accidents-map').setView([52.406263, 16.925107], 11); // eslint-disable-line

        // Ładowanie tła mapy
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '<a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a>',
            tileSize: 256
        }).addTo(mapObject);

        // Kawałek kodu odpowiadający za wsparcie markerCluster dla polygon
        L.Polygon.addInitHook(function () {
            this._latlng = this._bounds.getCenter(); // eslint-disable-line no-underscore-dangle
        });
        L.Polygon.include({
            getLatLng: function () {
                return this._latlng; // eslint-disable-line no-underscore-dangle
            },
            // Ta pusta funkcja musi tu być! ;c
            setLatLng: function () {} // eslint-disable-line no-empty-function
        });

        // Tworzymy grupę znaczników
        markersGroup = L.markerClusterGroup();

        refreshMapPoints();
    }
}

/**
 * Funkcja odświeża punkty dostępne na mapie.
 *
 * @memberof module:Awarie
 * @version 1.0.0
 */
export function refreshMapPoints () {
    if (mapObject !== null) {
        const $inputMapPoints = $section.find('#js-accidents-input-map-points'),
            mapPointsVal = $inputMapPoints.val();

        // Czyścimy aktualne markery
        markersGroup.clearLayers();
        $accidentsItemWrapper.removeClass('is-visible');

        if (mapPointsVal !== '') {
            const mapPoints = JSON.parse($inputMapPoints.val());

            // Dodajemy nowe markery
            mapPoints.forEach((element) => {
                if (element.coordinates.length === 1) {
                    // Tworzenie markera
                    const markerIcon = L.icon({
                            iconUrl: `${mda_theme_url}/dist/img/leaflet/marker-accident-${element.category}.png`, // eslint-disable-line camelcase
                            iconSize: [128, 128],    // eslint-disable-line
                            iconAnchor: [64, 64],   // eslint-disable-line
                            popupAnchor: [0, -128] // eslint-disable-line
                        }),
                        marker = L.marker([
                            element.coordinates[0].lat,
                            element.coordinates[0].lng
                        ], {icon: markerIcon})
                            .on('click', (event) => {
                                const accident = getAccident(element.id);

                                $accidentsItemWrapper
                                    .html(accident.html)
                                    .addClass('is-visible');
                                mapObject.setView(event.target.getLatLng(), 15); // eslint-disable-line no-magic-numbers
                            });

                    markersGroup.addLayer(marker);
                } else if (1 < element.coordinates.length) {
                    // Tworzenie obszaru
                    let areaColor = '#00aeef';

                    switch (element.category) {
                    case 'accident':
                        areaColor = '#df1801';
                        break;
                    case 'planned-work':
                        areaColor = '#016dda';
                        break;
                    default:
                        areaColor = '#00aeef';
                        break;
                    }

                    const polygon = L.polygon(element.coordinates, { // eslint-disable-line
                        color: areaColor
                    }).on('click', (event) => {
                        const accident = getAccident(element.id);

                        $accidentsItemWrapper
                            .html(accident.html)
                            .addClass('is-visible');
                        mapObject.setView(event.target.getLatLng(), 15); // eslint-disable-line no-magic-numbers
                    });
                    markersGroup.addLayer(polygon);
                } else {
                    console.error(`Nieprawidłowe koordynaty dla punktu ${element.id}`);
                }
            });

            mapObject.addLayer(markersGroup);

            const markersGroupBounds = markersGroup.getBounds(); // eslint-disable-line one-var

            if (0 < Object.keys(markersGroupBounds).length) {
                mapObject.fitBounds(markersGroupBounds);
            }
        }
    }
}

/**
 * [...]
 *
 * @memberof module:Awarie
 * @version 1.0.0
 */
function makeMobileSearch () {
    const $buttonOpen = $('#js-accident-mobile-search-button-open'),
        $buttonClose = $('#js-accident-mobile-search-button-close'),
        $container = $('#js-accident-mobile-search');

    $buttonOpen.on('click', () => {
        $container.addClass('is-visible');
    });

    $buttonClose.on('click', () => {
        $container.removeClass('is-visible');
    });
}

/**
 * Funkcja odpowiada za wybranie odpowiedniej kategorii w przypadku, jeżeli kategoria
 * zostanie przesłana w linku jako GET.
 *
 * @memberof module:Awarie
 * @version 1.0.0
 */
function selectCategory () {
    const category = getUrlParameter('category'),
        $switchAccidentCategoryButtons = $('.js-switch-accident-category-button'),
        $switchAccidentCategorySelector = $('#js-wip-and-accident-category-selector');

    if (category !== false && $.inArray(category, [
        'accident',
        'planned-work',
        'impediment'
    ]) !== -1) {
        // Desktop
        $switchAccidentCategoryButtons.each(function () { // eslint-disable-line consistent-return
            if ($(this).attr('data-category') === category) {
                $(this).trigger('click');

                return true;
            }
        });

        // Mobile
        $switchAccidentCategorySelector.val(category);
        $switchAccidentCategorySelector.trigger('change');
    }
}

/**
 * Funkcja wyciągająca wartość z parametru przekazanego w GET.
 *
 * @param {string} sParam - szukany parametr
 * @returns {*} value/false
 * @version 1.0.0
 */
export function getUrlParameter (sParam) {
    const sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&');

    let sParameterName = null,
        iteration = null;

    for (iteration = 0; iteration < sURLVariables.length; iteration++) {
        sParameterName = sURLVariables[iteration].split('=');

        if (sParameterName[0] === sParam) {
            return typeof sParameterName[1] === 'undefined' ? true : decodeURIComponent(sParameterName[1]);
        }
    }

    return false;
}
