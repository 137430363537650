/**
 * Init
 *
 * @module FC_Files
 */
export default function makeFlexibleFiles () {
    makeFilesExpandLogic();
}

/**
 * Funkcja odpowiada za usunięcie przycisku "Pokaż wszystkie" po rozwinięciu collapse,
 * a także zarządza animacją wyświetlania kolejnych plików.
 *
 * @memberof module:FC_Files
 * @version 1.0.0
 */
function makeFilesExpandLogic () {
    const $filesCollapse = $('.js-fc-files-collapse');

    if (0 < $filesCollapse.length) {
        $filesCollapse.on('shown.bs.collapse', function () {
            const collapseID = $(this).attr('id'),
                $thisCollapse = $(this);

            $thisCollapse.find('.js-fc-file-wrapper').each(function () {
                $(this).removeClass('is-hidden');
            });

            $('.js-fc-files-collapse-link').each(function () {
                const linkHyperlink = $(this).attr('href');

                if (linkHyperlink.replace('#', '') === collapseID) {
                    $(this).hide();
                }
            });
        });
    }
}
