import {getInvestment} from './ajax/get-investments';

/* global L, mda_theme_url */

let mapObject = null,
    markersGroup = null;

const $section = $('#js-section-investments'),
    $investmentsItemWrapper = $('#js-leaflet-investments-map-investment-item-wrapper');

/**
 * Init
 *
 * @module Inwestycje
 */
export default function makeInvestmentsLogic () {
    makeFsProjectsCollapse();
    makeMapLogic();
}

/**
 * Funkcja odpowiada za logikę rozwijanej listy "Projektów FS".
 *
 * @memberof module:Inwestycje
 * @version 1.0.0
 */
function makeFsProjectsCollapse () {
    const $fsButton = $('#js-investments-fs-button'),
        $fsBox = $('#js-investments-fs-box');

    $fsButton.on('click', function () {
        if ($fsBox.hasClass('is-visible')) {
            $(this).removeClass('is-opened');
            $fsBox.removeClass('is-visible');
        } else {
            $(this).addClass('is-opened');
            $fsBox.addClass('is-visible');
        }
    });
}

/**
 * Funkcja odpowiada za logikę otwierania i ładowania mapy, a także logikę samej mapy.
 *
 * @memberof module:Inwestycje
 * @version 1.0.0
 */
function makeMapLogic () {
    const $mapCollapse = $section.find('#js-investments-map-collapse'),
        $mapCollapseLinkText = $section.find('#js-investments-map-collapse-text'),
        $mapBox = $section.find('#js-investments-content-map-box');

    // Ładujemy mapę
    $mapCollapse.on('show.bs.collapse', () => {
        $mapCollapseLinkText.html($mapCollapseLinkText.data('hide'));
    });

    // Ładujemy i pokazujemy mapę
    $mapCollapse.on('shown.bs.collapse', () => {
        // Inicjujemy mapę jeżeli jeszcze nie została zainicjalizowana
        if (mapObject === null) {
            makeMap();
        }

        // Pokazujemy mapę
        $mapBox.addClass('is-visible');
    });

    // Zamykamy mapę (ale bez zabijania mapy, bo nie ma sensu jej zabijać)
    $mapCollapse.on('hide.bs.collapse', () => {
        $mapCollapseLinkText.html($mapCollapseLinkText.data('show'));
        $mapBox.removeClass('is-visible');
    });
}

/**
 * Funkcja odpowiada za obsługę interaktywnej mapy na inwestycjach.
 *
 * @memberof module:Inwestycje
 * @version 1.0.0
 */
function makeMap () {
    const $mapDiv = $('#js-leaflet-investments-map');

    if (0 < $mapDiv.length) {
        mapObject = L.map('js-leaflet-investments-map').setView([52.406263, 16.925107], 11); // eslint-disable-line

        // Ładowanie tła mapy
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '<a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a>',
            tileSize: 256
        }).addTo(mapObject);

        // Kawałek kodu odpowiadający za wsparcie markerCluster dla polygon
        L.Polygon.addInitHook(function () {
            this._latlng = this._bounds.getCenter(); // eslint-disable-line no-underscore-dangle
        });
        L.Polygon.include({
            getLatLng: function () {
                return this._latlng; // eslint-disable-line no-underscore-dangle
            },
            // Ta pusta funkcja musi tu być! ;c
            setLatLng: function () {} // eslint-disable-line no-empty-function
        });

        // Tworzymy grupę znaczników
        markersGroup = L.markerClusterGroup();

        refreshMapPoints();
    }
}

/**
 * Funkcja odświeża punkty dostępne na mapie.
 *
 * @memberof module:Inwestycje
 * @version 1.0.0
 */
export function refreshMapPoints () {
    if (mapObject !== null) {
        const $inputMapPoints = $section.find('#js-investments-input-map-points'),
            mapPointsVal = $inputMapPoints.val();

        // Czyścimy aktualne markery
        markersGroup.clearLayers();
        $investmentsItemWrapper.removeClass('is-visible');

        if (mapPointsVal !== '') {
            const mapPoints = JSON.parse($inputMapPoints.val());

            // Dodajemy nowe markery
            mapPoints.forEach((element) => {
                if (element.coordinates.length === 1) {
                    // Tworzenie markera
                    const markerIcon = L.icon({
                            iconUrl: `${mda_theme_url}/dist/img/leaflet/marker-default.png`, // eslint-disable-line camelcase
                            iconSize: [24, 24],     // eslint-disable-line
                            iconAnchor: [12, 24],   // eslint-disable-line
                            popupAnchor: [0, -24]   // eslint-disable-line
                        }),
                        marker = L.marker([
                            element.coordinates[0].lat,
                            element.coordinates[0].lng
                        ], {icon: markerIcon})
                            .on('click', (event) => {
                                const investment = getInvestment(element.id);

                                $investmentsItemWrapper
                                    .html(investment.html)
                                    .addClass('is-visible');
                                mapObject.setView(event.target.getLatLng(), 15); // eslint-disable-line no-magic-numbers
                            });

                    marker.addTo(mapObject);
                    markersGroup.addLayer(marker);
                } else if (1 < element.coordinates.length) {
                    // Tworzenie obszaru
                    const polygon = L.polygon(element.coordinates, { // eslint-disable-line
                        color: '#00aeef'
                    }).on('click', (event) => {
                        const investment = getInvestment(element.id);

                        $investmentsItemWrapper
                            .html(investment.html)
                            .addClass('is-visible');
                        mapObject.setView(event.target.getLatLng(), 15); // eslint-disable-line no-magic-numbers
                    });

                    polygon.addTo(mapObject);
                    markersGroup.addLayer(polygon);
                } else {
                    console.error(`Nieprawidłowe koordynaty dla punktu ${element.id}`);
                }
            });

            // mapObject.addLayer(markersGroup);

            const markersGroupBounds = markersGroup.getBounds(); // eslint-disable-line one-var

            if (0 < Object.keys(markersGroupBounds).length) {
                mapObject.fitBounds(markersGroupBounds);

                setTimeout(() => {
                    mapObject.setView([52.406263, 16.925107], 11);
                }, 500);
            }
        }
    }
}
