/* global L, mda_theme_url */

/**
 * Init
 *
 * @module Incydent
 */
export default function makeAccident () {
    const mapIds = [],
        $mapDesktop = $('.js-leaflet-accident-map-desktop'),
        $mapMobile = $('#js-leaflet-accident-map-mobile'),
        $mobleCollapse = $('#js-accident-map-mobile-collapse');

    let isMobileMapInitalizated = false;

    $mapDesktop.each(function () {
        mapIds.push($(this));
    });

    mapIds.forEach((element) => {
        if (0 < element.length) {
            makeMap(element);
        }
    });

    $mobleCollapse.on('shown.bs.collapse', () => {
        if (0 < $mapMobile.length && isMobileMapInitalizated === false) {
            makeMap($mapMobile);
            isMobileMapInitalizated = true;
        }
    });
}

/**
 * Funkcja odpowiada za obsługę interaktywnej mapy na awarii.
 *
 * @memberof module:Incydent
 * @param {object} $mapDiv - obiekt jQuery z mapą.
 * @version 1.0.0
 */
function makeMap ($mapDiv) {
    if (0 < $mapDiv.length) {
        const coords = JSON.parse($mapDiv.attr('data-points')),
            accidentCategory = $mapDiv.attr('data-category'),
            mapObject = L.map($mapDiv[0].id).setView([coords[0].lat, coords[0].lng], 16), // eslint-disable-line
            marker = L.icon({
                iconUrl: `${mda_theme_url}/dist/img/leaflet/marker-accident-${accidentCategory}.png`, // eslint-disable-line camelcase
                iconSize: [128, 128],      // eslint-disable-line
                iconAnchor: [64, 64],     // eslint-disable-line
                popupAnchor: [0, -128]   // eslint-disable-line
            });

        // Ładowanie tła mapy
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '<a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a>',
            tileSize: 256
        }).addTo(mapObject);

        // Tworzenie markera lub obszaru
        if (coords.length === 1) {
            // Tworzenie markera
            L.marker([
                coords[0].lat,
                coords[0].lng
            ], {icon: marker})
                .addTo(mapObject);
        } else if (1 < coords.length) {
            const trueCoords = [];

            coords.forEach((element) => {
                trueCoords.push([
                    element.lat,
                    element.lng
                ]);
            });

            // Tworzenie obszaru
            let areaColor = '#00aeef';

            switch (accidentCategory) {
            case 'accident':
                areaColor = '#df1801';
                break;
            case 'planned-work':
                areaColor = '#016dda';
                break;
            default:
                areaColor = '#00aeef';
                break;
            }

            const polygon = L.polygon(trueCoords, { // eslint-disable-line
                    color: areaColor
                }).addTo(mapObject),
                polygonBounds = polygon.getBounds();

            if (0 < Object.keys(polygonBounds).length) {
                mapObject.fitBounds(polygonBounds);
            }
        } else {
            console.error('Nieprawidłowe dane punktów!');
        }
    }
}
