/**
 * Init dla funkcji związanych z dynamicznymi licznikami.
 *
 * @module Number_Counters
 */
export default function makeNumberCounters () {
    const $numberCounters = $('.js-number-counters');

    if (0 < $numberCounters.length) {
        $(window).on('load', () => {
            makeCountersCount($numberCounters);
        });

        $(window).on('scroll', () => {
            makeCountersCount($numberCounters);
        });
    }
}

/**
 * Funkcja odpowiada za odpalenie dynamicznych liczników dla liczb.
 *
 * @memberof module:Number_Counters
 * @param {*} $numberCounters - obiekt div'a, w którym znajdują się liczniki.
 */
function makeCountersCount ($numberCounters) {
    if ($numberCounters.offset().top < $(document).scrollTop() + $(window).height()) {
        if (!$numberCounters.hasClass('is-fired')) {
            $numberCounters.addClass('is-fired');
            $numberCounters.find('.js-number-counter').each(function () {
                let current = 0;

                const el = $(this),
                    value = $(this).data('count-to'),
                    end = value,
                    start = 0,
                    increment = end > start ? 1 : -1,
                    stepTime = 1000 / value,
                    timer = setInterval(() => {
                        current += increment;
                        el.html(current);
                        if (current === end) {
                            clearInterval(timer);
                        }
                    }, stepTime);
            });
        }
    }
}
