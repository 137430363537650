/* eslint-disable max-lines */

/**
 * Init
 */
export default function makeOnlineForms () {
    makeDefaultCheckboxCollapse();
    makeDefaultRadioCollapse();
    makeYesNoFalseCollapse();
    makeCompanyTypeSelectionCollapse();
    makeMailingCollapse();
    makeMailFVAT();
    makeUndergroundGarage();
    makeCustomWebBrowserValidations();
    makeCustomGetDocumentsValidation();
    makeCustomGetAttachmentsValidation();
    makeOneFromTwoRequired();
    makeCheckboxLock();
    makeInvestorsRepater();
}

/**
 * Funkcja rozwijająca i zwijająca collapse, głównie związanych z wyborem
 * typu klienta: 'Klient indywidualny' VS 'Firma'.
 *
 * TODO: Prawdopodobnie można zastąpić działanie tej funkcji przez funkcję makeDefaultRadioCollapse() !
 *
 * Dodatkowo funkcja oznacza pola (muszą mieć parametr "data-collapsed-required = true")
 * jako pola wymagane do uzupełnenia w przypadku jego rozwinięcia, lub jako
 * nie wymagane do uzupełnienia w przypadku zwinięcia collapse.
 *
 * @version 1.2.1
 */
function makeCompanyTypeSelectionCollapse () {
    const $checkbox1 = $('.js-online-form-company-type-checkbox'),
        $collapse1a = $('#js-online-form-company-type-collapse-1a'),
        $collapse1b = $('#js-online-form-company-type-collapse-1b'),
        $collapse1c = $('#js-online-form-company-type-collapse-1c'),
        $additionalSelector = $('.js-online-form-water-getter-purpose'),
        $additionalAlert = $('#js-online-form-water-getter-alert');

    $checkbox1.on('change', function () {
        if ($(this).val() === 'individual-customer') {
            $collapse1a.collapse('show');
            $collapse1b.collapse('hide');
            $collapse1c.collapse('show');

            // Required na inputs - ON
            // eslint-disable-next-line array-element-newline
            $collapse1a.each(function () {
                $(this).find('input, textarea').each(function () {
                    if ($(this).attr('data-collapsed-required') === 'true') {
                        $(this).prop('required', true);
                    }
                });
            });
            $collapse1c.each(function () {
                $(this).find('input, textarea').each(function () {
                    if ($(this).attr('data-collapsed-required') === 'true') {
                        $(this).prop('required', true);
                    }
                });
            });

            // Required na inputs - OFF
            $collapse1b.find('input, textarea').each(function () {
                if ($(this).attr('data-collapsed-required') === 'true') {
                    $(this)
                        .removeClass('is-invalid')
                        .prop('required', false);
                }
            });

            // Zaznaczenie dodatkowego pola w formularzu "AQ-HK-03 | Wniosek o zmiane danych objętych umową".
            if (0 < $additionalSelector.length) {
                $additionalSelector
                    .val('31')
                    .trigger('change')
                    .addClass('is-locked');
                $additionalAlert.removeClass('is-hidden');
            }
        } else {
            $collapse1a.collapse('hide');
            $collapse1b.collapse('show');
            $collapse1c.collapse('hide');

            // Required na inputs - ON
            $collapse1b.find('input, textarea').each(function () {
                if ($(this).attr('data-collapsed-required') === 'true') {
                    $(this).prop('required', true);
                }
            });

            // Required na inputs - OFF
            // eslint-disable-next-line array-element-newline
            $collapse1a.each(function () {
                $(this).find('input, textarea').each(function () {
                    if ($(this).attr('data-collapsed-required') === 'true') {
                        $(this)
                            .removeClass('is-invalid')
                            .prop('required', false);
                    }
                });
            });
            $collapse1c.each(function () {
                $(this).find('input, textarea').each(function () {
                    if ($(this).attr('data-collapsed-required') === 'true') {
                        $(this)
                            .removeClass('is-invalid')
                            .prop('required', false);
                    }
                });
            });

            // Zaznaczenie dodatkowego pola w formularzu "AQ-HK-03 | Wniosek o zmiane danych objętych umową".
            if (0 < $additionalSelector.length) {
                $additionalSelector.removeClass('is-locked');
                $additionalAlert.addClass('is-hidden');
            }
        }
    });
}

/**
 * [...]
 *
 * Dodatkowo funkcja oznacza pola adresu korespondencyjnego (muszą mieć parametr "data-collapsed-required = true")
 * jako pola wymagane do uzupełnenia w przypadku jego rozwinięcia, lub jako
 * nie wymagane do uzupełnienia w przypadku zwinięcia collapse.
 *
 * @version 1.1.0
 */
function makeDefaultCheckboxCollapse () {
    const $checkboxSource = $('.js-online-form-check-checkbox'),
        $collapseSource = $('.js-online-form-check-collapse');

    $checkboxSource.on('change', function () {
        const $this = $(this),
            openCollapse = $(this).attr('data-collapse-to-open');

        $collapseSource.each(function () {
            if ($(this).attr('data-collapse-to-open') === openCollapse) {
                if ($this.is(':checked')) {
                    $(this).collapse('show');

                    // Required na inputs - ON
                    $(this).find('input, textarea').each(function () {
                        if ($(this).attr('data-collapsed-required') === 'true') {
                            $(this).prop('required', true);
                        }
                    });

                    const $inputAttachment = $('.js-input-attachment');

                    if($inputAttachment){
                        $inputAttachment.prop('required', true);
                    }



                } else {
                    $(this).collapse('hide');

                    // Required na inputs - OFF
                    $(this).find('input, textarea').each(function () {
                        if ($(this).attr('data-collapsed-required') === 'true') {
                            $(this)
                                .removeClass('is-invalid')
                                .prop('required', false);
                        }
                    });

                    const $inputAttachment = $('.js-input-attachment');

                    if($inputAttachment){
                        $inputAttachment.prop('required', false);
                    }
                }
            }
        });
    });
}

const $validateRequired = $('.js-validate-required');

$validateRequired.each(function() {
    $(this).attr('required', 'required');
});

$validateRequired.on('change', function() {
    if ($(this).prop('checked')) {
        $validateRequired.prop('required', false);
        $(this).prop('required', true);
    } else {
        let anyChecked = false;
        $validateRequired.each(function() {
            if ($(this).prop('checked')) {
                anyChecked = true;
                return false;
            }
        });
        if (!anyChecked) {
            $validateRequired.prop('required', true);
        }
    }
});

/**
 * [...]
 *
 * Dodatkowo funkcja oznacza pola adresu korespondencyjnego (muszą mieć parametr "data-collapsed-required = true")
 * jako pola wymagane do uzupełnenia w przypadku jego rozwinięcia, lub jako
 * nie wymagane do uzupełnienia w przypadku zwinięcia collapse.
 *
 * @version 1.2.0
 */
function makeDefaultRadioCollapse () {
    const $checkbox = $('.js-online-form-radio-checkbox'),
        $collapse = $('.js-online-form-radio-collapse');

    // Przypisanie identyfikatora dla collapse
    $checkbox.each(function () {
        const $thisCheckbox = $(this),
            openCollapse = $(this).attr('data-collapse-to-open');

        $collapse.each(function () {
            if ($(this).attr('data-collapse-to-open') === openCollapse) {
                $(this).attr('data-collapse-trigger-name', $thisCheckbox.attr('name'));
            }
        });
    });

    $checkbox.on('change', function () {
        const $thisCheckbox = $(this),
            openCollapse = $(this).attr('data-collapse-to-open');

        $collapse.each(function () {
            if ($(this).attr('data-collapse-trigger-name') === $thisCheckbox.attr('name')) {
                if ($(this).attr('data-collapse-to-open') === openCollapse) {
                    $(this).collapse('show');

                    // Required na inputs - ON
                    $(this).find('input, textarea').each(function () {
                        if ($(this).attr('data-collapsed-required') === 'true') {
                            $(this).prop('required', true);
                        }
                    });
                } else {
                    $(this).collapse('hide');

                    // Required na inputs - OFF
                    $(this).find('input, textarea').each(function () {
                        if ($(this).attr('data-collapsed-required') === 'true') {
                            $(this)
                                .removeClass('is-invalid')
                                .prop('required', false);
                        }
                    });
                }
            }
        });
    });
}

/**
 * Funkcja rozwijająca lub zwijająca collapse na podstawie odpowiedzi 'Tak/Nie' z radiobox'a.
 *
 * @version 1.0.0
 */
function makeYesNoFalseCollapse () {
    const $checkbox = $('.js-online-form-yesno-false-checkbox'),
        $collapse = $('#js-online-form-yesno-false-collapse');

    $checkbox.on('change', function () {
        if ($(this).val() === 'no') {
            $collapse.collapse('show');
        } else {
            $collapse.collapse('hide');
        }
    });
}

/**
 * Funkcja rozwijająca i zwijająca dodatkowe dane adresowe w przypadku, jeżeli adres
 * korespondencyjny jest inny niż adres zamieszkania.
 *
 * Dodatkowo funkcja oznacza pola adresu korespondencyjnego (wszystkie wewnątrz tego collapse)
 * jako pola wymagane do uzupełnenia w przypadku jego rozwinięcia, lub jako
 * nie wymagane do uzupełnienia w przypadku zwinięcia collapse.
 *
 * @version 1.1.0
 */
function makeMailingCollapse () {
    const $checkbox = $('.js-online-form-mailing-address-checkbox'),
        $collapse = $('#js-online-form-mailing-address-collapse');

    $checkbox.on('change', function () {
        if ($(this).is(':checked')) {
            $collapse.collapse('show');

            // Required na inputs - ON
            $collapse.find('input, textarea').each(function () {
                if (!$(this).hasClass('js-no-required')) {
                    $(this).prop('required', true);
                }
            });
        } else {
            $collapse.collapse('hide');

            // Required na inputs - OFF
            $collapse.find('input, textarea').each(function () {
                if (!$(this).hasClass('js-no-required')) {
                    $(this).prop('required', false);
                }
            });
        }
    });
}

/**
 * Funkcja oznacza pole "Adres e-mail" jako wymagane w przypadku, w którym user
 * zaznaczy, że chce otrzymać fakturę VAT na adres email.
 *
 * @version 1.1.0
 */
function makeMailFVAT () {
    const $checkbox = $('.js-online-form-fvat-checkbox'),
        $input = $('.js-online-form-fvat-input'),
        $inputDescription = $('#js-online-form-fvat-input-description');

    let realTrue = null;

    $checkbox.on('change', function () {
        // Różne warunki logiczne w zależności od typu - radio / checkbox
        if ($checkbox.attr('type') === 'radio') {
            realTrue = $(this).val() === 'yes';
        } else if ($checkbox.attr('type') === 'checkbox') {
            realTrue = $(this).prop('checked');
        }

        // Akcja na prawdę / fałsz
        if (realTrue) {
            $input.prop('required', true);
            $inputDescription.hide();
        } else {
            $input.prop('required', false);
            $inputDescription.show();
        }
    });
}

/**
 * Funkcja oznacza pole "Załącznik z planem zagospodarowania" jako wymagane w przypadku, w którym user
 * zaznaczy, że chce na działce znajduje się garaż podziemny.
 *
 * @version 1.0.0
 */
function makeUndergroundGarage () {
    const $checkbox = $('.js-online-form-underground-garage-checkbox'),
        $input = $('.js-online-form-underground-garage-input');

    let realTrue = null;

    $checkbox.on('change', function () {
        // Różne warunki logiczne w zależności od typu - radio / checkbox
        if ($checkbox.attr('type') === 'radio') {
            realTrue = $(this).val() === 'yes';
        } else if ($checkbox.attr('type') === 'checkbox') {
            realTrue = $(this).prop('checked');
        }

        // Akcja na prawdę / fałsz
        if (realTrue) {
            $input.prop('required', true);
        } else {
            $input.prop('required', false);
        }
    });
}

/**
 * Funkcja dodająca custom'ową walidację przeglądarkową do formularza na stronie.
 *
 * @see https://vyspiansky.github.io/2019/07/13/javascript-at-least-one-checkbox-must-be-selected/.
 * @version 1.1.0
 */
function makeCustomWebBrowserValidations () {
    const form = document.querySelector('.js-of-form');

    let checkboxes = null,
        checkboxLength = null,
        firstCheckbox = null;

    if (form !== null) {
        checkboxes = form.querySelectorAll('input.js-checkbox-one-from-all');
        checkboxLength = checkboxes.length;
        firstCheckbox = checkboxLength > 0 ? checkboxes[0] : null;

        if (firstCheckbox) {
            for (let iteration = 0; iteration < checkboxLength; iteration++) {
                checkboxes[iteration].addEventListener('change', checkValidity);
            }

            checkValidity();
        }
    }

    /**
     * [...]
     */
    function checkValidity () {
        let status = false;

        for (let iteration = 0; iteration < checkboxLength; iteration++) {
            if (checkboxes[iteration].checked) {
                status = true;
            }
        }

        // eslint-disable-next-line no-negated-condition
        const errorMessage = !status ? 'Przynajmniej jeden checkbox w tym pytaniu musi zostać zaznaczony!' : '';

        for (let iteration = 0; iteration < checkboxLength; iteration++) {
            checkboxes[iteration].setCustomValidity(errorMessage);
        }
    }
}

/**
 * Funkcja dodająca custom'ową walidację przeglądarkową do formularza na stronie.
 * TODO: Zrobić merge tej funkcji z powyższą, żeby było ładniej.
 *
 * @see https://vyspiansky.github.io/2019/07/13/javascript-at-least-one-checkbox-must-be-selected/.
 * @version 1.1.0
 */
function makeCustomGetDocumentsValidation () {
    const form = document.querySelector('.js-of-form');

    let checkboxes = null,
        checkboxLength = null,
        firstCheckbox = null;

    if (form !== null) {
        checkboxes = form.querySelectorAll('input.js-checkbox-one-from-all-documents');
        checkboxLength = checkboxes.length;
        firstCheckbox = checkboxLength > 0 ? checkboxes[0] : null;

        if (firstCheckbox) {
            for (let iteration = 0; iteration < checkboxLength; iteration++) {
                checkboxes[iteration].addEventListener('change', checkValidityGetDocuments);
            }

            checkValidityGetDocuments();
        }
    }

    /**
     * [...]
     */
    function checkValidityGetDocuments () {
        let status = false;

        for (let iteration = 0; iteration < checkboxLength; iteration++) {
            if (checkboxes[iteration].checked) {
                status = true;
            }
        }

        // eslint-disable-next-line no-negated-condition
        const errorMessage = !status ? 'Przynajmniej jeden checkbox w tym pytaniu musi zostać zaznaczony!' : '';

        for (let iteration = 0; iteration < checkboxLength; iteration++) {
            checkboxes[iteration].setCustomValidity(errorMessage);
        }
    }
}

/**
 * Funkcja dodająca custom'ową walidację przeglądarkową do formularza na stronie.
 * TODO: Zrobić merge tej funkcji z powyższą, żeby było ładniej.
 *
 * @see https://vyspiansky.github.io/2019/07/13/javascript-at-least-one-checkbox-must-be-selected/.
 * @version 1.1.0
 */
function makeCustomGetAttachmentsValidation () {
    const form = document.querySelector('.js-of-form');

    let checkboxes = null,
        checkboxLength = null,
        firstCheckbox = null;

    if (form !== null) {
        checkboxes = form.querySelectorAll('input.js-checkbox-one-from-all-attachments');
        checkboxLength = checkboxes.length;
        firstCheckbox = checkboxLength > 0 ? checkboxes[0] : null;

        if (firstCheckbox) {
            for (let iteration = 0; iteration < checkboxLength; iteration++) {
                checkboxes[iteration].addEventListener('change', checkValidityGetAttachments);
            }

            checkValidityGetAttachments();
        }
    }

    /**
     * [...]
     */
    function checkValidityGetAttachments () {
        let status = false;

        for (let iteration = 0; iteration < checkboxLength; iteration++) {
            if (checkboxes[iteration].checked) {
                status = true;
            }
        }

        // eslint-disable-next-line no-negated-condition
        const errorMessage = !status ? 'Przynajmniej jeden checkbox w tym pytaniu musi zostać zaznaczony!' : '';

        for (let iteration = 0; iteration < checkboxLength; iteration++) {
            checkboxes[iteration].setCustomValidity(errorMessage);
        }
    }
}

/**
 * Przynajmniej jeden z dwóch pól input jest wymagany.
 *
 * @version 1.0.0
 */
function makeOneFromTwoRequired () {
    const $inputs = $('.js-one-from-two-inputs');

    $inputs.prop('required', true);

    $inputs.on('change keyup', () => {
        let filledFlag = false;

        $inputs.each(function () {
            if ($(this).val() !== '' && $(this).val() !== '(+48) ') {
                filledFlag = true;
            }
        });

        if (filledFlag === true) {
            $inputs
                .removeClass('is-invalid')
                .prop('required', false);
        } else {
            $inputs.prop('required', true);
        }
    });
}

/**
 * Funkcja odpowiadająca za zablokowanie wyboru checkboxów w przypadku wyboru triggera.
 *
 * @version 1.0.0
 */
function makeCheckboxLock () {
    const $checkboxLock = $('.js-checkbox-lock');

    $checkboxLock.on('click', function () {
        const $this = $(this);

        if ($this.attr('data-lock-trigger')) {
            if ($this.is(':checked')) {
                $checkboxLock.each(function () {
                    if ($this.attr('id') !== $(this).attr('id')) {
                        $(this)
                            .prop('disabled', true)
                            .prop('checked', false)
                            .trigger('change');
                    }
                });
            } else {
                $checkboxLock.prop('disabled', false);
            }
        }
    });
}

/**
 * Funkcja odpowiadająca za zarządzanie logiką dodawania i usuwania inwestorów na wniosku "AQ-IBM-04".
 *
 * @version 1.0.0
 */
function makeInvestorsRepater () {
    const $addInvestorButton = $('#js-form-add-investor-button'),
        $addInvestorWrapper = $('#js-form-add-investor-wrapper'),
        $addInvestorTemplate = $('.js-form-add-investor-template');

    /**
     * Akcja na przycisk "Dodaj inwestora"
     */
    $addInvestorButton.on('click', (event) => {
        event.preventDefault();

        $addInvestorWrapper.append($addInvestorTemplate.clone());

        $addInvestorWrapper.find('.js-form-add-investor-remove').each(function () {
            if ($(this).hasClass('d-none')) {
                $(this).removeClass('d-none');
            }
        });

        $addInvestorWrapper.find('.js-form-add-investor-counter').each(function (number) {
            $(this).html(number + 2);
        });
    });

    /**
     * Akcja na przycisk usuwania inwestora
     */
    $addInvestorWrapper.on('click', '.js-form-add-investor-remove', function (event) {
        event.preventDefault();

        $(this).closest('.js-form-add-investor-template').addClass('is-removing');

        // Zgranie z animacją trwającą 1 sek ^^
        setTimeout(() => {
            $(this).closest('.js-form-add-investor-template').remove();

            $addInvestorWrapper.find('.js-form-add-investor-counter').each(function (number) {
                $(this).html(number + 2);
            });
        }, 250);
    });

    /**
     * Usunięcie błędu na procentach w przypadku zmiany wartości w jakimś polu.
     */
    $('body').on('keyup', '.js-form-add-investor-percent', () => {
        $('.js-form-add-investor-percent').removeClass('is-invalid');
    });
}
