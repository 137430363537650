/**
 * Funkcja inicjalizująca slider'y na stronie.
 *
 * @module Sliders
 */
export default function makeSliders () {
    $(window).on('load', () => {
        makeHomeBannerSlider();
        makeHomeNewsSlider();
        makeSectionInvestmentsSlider();
        makeFooterLogosSlider();
        makeSectionCareerSlider();
    });
}

/**
 * Funkcja zarządzająca logiką slider'a w header zawierającego banner.
 *
 * @memberof module:Sliders
 * @version 1.0.0
 */
function makeHomeBannerSlider () {
    const $homeBannerSlider = $('#js-home-welcome'),
    $homeBannerSlide = $homeBannerSlider.find('#js-home-welcome-slider'),
    $homeBannerSliderPrev = $homeBannerSlider.find('#js-home-welcome-slider-prev'),
    $homeBannerSliderNext = $homeBannerSlider.find('#js-home-welcome-slider-next'),
    $homeBannerSliderDots = $homeBannerSlider.find('#js-home-welcome-slider-dots');

    if (0 < $homeBannerSlide.length) {
        $homeBannerSlide.slick({
            appendDots: $homeBannerSliderDots,
            dots: true,
            nextArrow: $homeBannerSliderNext,
            prevArrow: $homeBannerSliderPrev,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
        });
    }
}

/**
 * Funkcja zarządzająca logiką slider'a z news'ami.
 *
 * @memberof module:Sliders
 * @version 1.0.0
 */
function makeHomeNewsSlider () {
    const $homeNews = $('#js-home-news'),
        $homeNewsSlider = $homeNews.find('#js-home-news-slider'),
        $homeNewsSliderPrev = $homeNews.find('#js-home-news-slider-prev'),
        $homeNewsSliderNext = $homeNews.find('#js-home-news-slider-next'),
        $homeNewsSliderDots = $homeNews.find('#js-home-news-slider-dots');

    if (0 < $homeNewsSlider.length) {
        $homeNewsSlider.slick({
            adaptiveHeight: true,
            appendDots: $homeNewsSliderDots,
            dots: true,
            fade: true,
            infinite: true,
            nextArrow: $homeNewsSliderNext,
            prevArrow: $homeNewsSliderPrev,
            slidesToShow: 1
        });
    }
}

/**
 * Funkcja zarządzająca logiką slider'a z inwestycjami.
 *
 * @memberof module:Sliders
 * @version 1.0.0
 */
function makeSectionInvestmentsSlider () {
    const $sectionInvestments = $('#js-home-investments'),
        $sectionInvestmentsSlider = $sectionInvestments.find('#js-home-investments-slider'),
        $sectionInvestmentsSliderPrev = $sectionInvestments.find('#js-home-investments-slider-prev'),
        $sectionInvestmentsSliderNext = $sectionInvestments.find('#js-home-investments-slider-next'),
        $sectionInvestmentsSliderDots = $sectionInvestments.find('#js-home-investments-slider-dots');

    if (0 < $sectionInvestmentsSlider.length) {
        $sectionInvestmentsSlider.slick({
            appendDots: $sectionInvestmentsSliderDots,
            dots: true,
            infinite: false,
            nextArrow: $sectionInvestmentsSliderNext,
            prevArrow: $sectionInvestmentsSliderPrev,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ],
            slidesToShow: 3
        });
    }
}

/**
 * Funkcja zarządzająca logiką slider'a w footer zawierającego logo.
 *
 * @memberof module:Sliders
 * @version 1.0.0
 */
function makeFooterLogosSlider () {
    const $footerLogosSlider = $('#js-footer-logos-slider');

    if (0 < $footerLogosSlider.length) {
        $footerLogosSlider.slick({
            dots: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 1500,
            slidesToShow: 5,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        });
    }
}

/**
 * Funkcja zarządzająca logiką slider'a z zdjęciami w kariera.
 *
 * @memberof module:Sliders
 * @version 1.0.0
 */
function makeSectionCareerSlider () {
    const $sectioncareer = $('#scroll-meet-us'),
        $sectioncareerSlider = $sectioncareer.find('#js-career-slider'),
        $sectioncareerSliderPrev = $sectioncareer.find('#js-career-slider-prev'),
        $sectioncareerSliderNext = $sectioncareer.find('#js-career-slider-next'),
        $sectioncareerSliderDots = $sectioncareer.find('#js-career-slider-dots');

    if (0 < $sectioncareerSlider.length) {
        $sectioncareerSlider.slick({
            appendDots: $sectioncareerSliderDots,
            dots: true,
            infinite: true,
            nextArrow: $sectioncareerSliderNext,
            prevArrow: $sectioncareerSliderPrev,
            slidesToShow: 1
        });
    }
}
