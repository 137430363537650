/* global L, mda_theme_url */

import {FileUpload} from './FileUpload.class';

/**
 * Init dla funkcji związanych z kontaktem.
 *
 * @module Kontakt
 */
export default function makeContactLogic () {
    makeMap();
    makeFilesUploadLogic();
}

/**
 * Funkcja odpowiada za obsługę interaktywnej mapy na kontakcie.
 *
 * @memberof module:Kontakt
 * @version 1.0.0
 */
function makeMap () {
    const $mapDiv = $('#js-leaflet-contact-map');

    if (0 < $mapDiv.length) {
        const markerCoords = {
                lat: $mapDiv.attr('data-lat'),
                lng: $mapDiv.attr('data-lng')
            },
            mapObject = L.map('js-leaflet-contact-map').setView([markerCoords.lat, markerCoords.lng], 12), // eslint-disable-line
            marker = L.icon({
                iconUrl: `${mda_theme_url}/dist/img/leaflet/marker-default.png`, // eslint-disable-line camelcase
                iconSize: [24, 24],     // eslint-disable-line
                iconAnchor: [12, 24],   // eslint-disable-line
                popupAnchor: [0, -24]   // eslint-disable-line
            });

        // Ładowanie tła mapy
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '<a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a>',
            tileSize: 256
        }).addTo(mapObject);

        // Tworzenie markera
        L.marker([
            markerCoords.lat,
            markerCoords.lng
        ], {icon: marker})
            .addTo(mapObject);
    }
}

/**
 * Funkcja odpowiada za logikę modułu do wysyłki plików.
 *
 * @memberof module:Kontakt
 * @version 1.0.0
 */
function makeFilesUploadLogic () {
    // Init file upload
    const element = document.querySelector('.js-file-upload'),
        form = document.querySelector('.js-cf-form');

    if (form !== null && element.length !== null) {
        new FileUpload(element, {
            maxFileSize: 50,
            fileTypeText: 'jpg, png, tiff, txt, pdf, doc(x), ppt(x), xls(x), ods, odt, odp, zip, rar, 7z',
            fileTypeRegexp: new RegExp("application\/(x-7z-compressed|x-rar-compressed|octet-stream|zip|pdf|vnd\.oasis\.opendocument\.text|vnd.oasis.opendocument.spreadsheet|vnd.oasis.opendocument.presentation|vnd.ms-excel|vnd.ms-powerpoint|vnd.openxmlformats-officedocument.spreadsheetml.sheet|vnd.openxmlformats-officedocument.presentationml.presentation|vnd.openxmlformats-officedocument.wordprocessingml.document)|text\/plain|image\/(jpeg|png|tiff)"),
        });

        form.addEventListener('reset', () => {
            element.fileUpload.resetUploader();
        });
    }
}
