/**
 * Init
 *
 * @module Sliding_Navigation
 */
export default function makeSlidingNavigationLogic () {
    refreshNavigation();
    makeNavigationLogic();
}

/**
 * Funkcja odświeżająca nawigację w postaci belek z przesuwanym tłem.
 * Powinna odświeżyć:
 * - wysokość tła,
 * - szerokość tła,
 * - pozycję tła.
 *
 * @memberof module:Sliding_Navigation
 * @version 1.0.0
 */
export function refreshNavigation () {
    const $nav = $('.js-sliding-navigation-nav');

    $nav.each(function () {
        const $navBackground = $(this).find('.js-sliding-navigation-nav-background'),
            $navLink = $(this).find('.js-sliding-navigation-nav-link');

        // Ustawiamy szerokość tła "na dzień dobry" ;>
        $navLink.each(function () { // eslint-disable-line consistent-return
            if ($(this).hasClass('active')) {
                const linkWidth = $(this).outerWidth(),
                    linkHeight = $(this).outerHeight(),
                    fromTop = $(this).position().top,
                    fromLeft = $(this).position().left;

                if (0 < linkWidth) {
                    $navBackground.height(linkHeight);
                    $navBackground.width(linkWidth + 2);
                    $navBackground.css('top', fromTop);
                    $navBackground.css('left', fromLeft - 1);

                    return false; // Break
                }
            }
        });
    });
}

/**
 * Funkcja zarządzająca logiką nawigacji w postaci belek z przesuwanym tłem.
 *
 * @memberof module:Sliding_Navigation
 * @version 1.0.0
 */
function makeNavigationLogic () {
    const $nav = $('.js-sliding-navigation-nav');

    $nav.each(function () {
        const $navBackground = $(this).find('.js-sliding-navigation-nav-background'),
            $navLink = $(this).find('.js-sliding-navigation-nav-link');

        $navLink.on('click', function (event) {
            const allowDefault = $(this).hasClass('allow-default'),
                linkWidth = $(this).outerWidth(),
                linkHeight = $(this).outerHeight(),
                fromTop = $(this).position().top,
                fromLeft = $(this).position().left;

            if (!allowDefault) {
                event.preventDefault();
            }

            // Zerujemy aktywne pozycje i wybieramy tylko kliknięty element
            if (!allowDefault) {
                $('.js-sliding-navigation-nav-link').removeClass('active');
                $(this).addClass('active');
            }

            // Ustawiamy nowe parametry dla tła
            $navBackground.height(linkHeight);
            $navBackground.width(linkWidth + 2);
            $navBackground.css('top', fromTop);
            $navBackground.css('left', fromLeft - 1);
        });
    });
}
