export class FileUpload {
    constructor (el, options = {}) {
        if (!el) {
            console.error('Cannot initialize file upload object without any element.');

            return;
        }

        // Set props
        this.el = el;
        this.options = {
            fileTypeText: 'jpg, png',
            fileTypeRegexp: 'u/^image//',
            maxFileSize: 2, // In MB
            maxFileNum: 9,
            renderPrev: true,

            customHandleUploadFile: () => false,

            ...options
        };

        this.fileList = [

            /* {
                id: 1,
                loading: true,
                name: 'File name',
                size: 'File size',
                file: File
                imageData: UrlData
            } */
        ];

        this.errors = [

            /* {
                filename: 'File name',
                error: 'Cannot add file because...',
            } */
        ];

        // Expose uploader instance
        this.el.uploader = this;

        // Redner input
        this.renderInput();

        // Add event listeners
        this.el.addEventListener('dragenter', this.onDragEnter.bind(this));
        this.el.addEventListener('dragleave', this.onDragLeave.bind(this));
        this.el.addEventListener('dragover', this.onDragOver.bind(this));
        this.el.addEventListener('drop', this.onDrop.bind(this));
        this.el.addEventListener('click', this.onClick.bind(this));

        this.input.addEventListener('change', this.onInputChange.bind(this));

        // Add file upload class instance as el prop
        this.el.fileUpload = this;
    }

    onClick () {
        this.showFileDialog();
    }

    onDragEnter () {
        this.el.classList.add('-drag');
    }

    onDragLeave () {
        this.el.classList.remove('-drag');
    }

    // eslint-disable-next-line class-methods-use-this
    onDragOver (event) {
        event.preventDefault();
    }

    onDrop (event) {
        event.preventDefault();
        this.el.classList.remove('-drag');

        const files = [...event.dataTransfer.files];

        this.uploadFiles(files);
    }

    onInputChange () {
        const files = [...this.input.files];

        this.uploadFiles(files);
    }

    onFileClick (e) {
        e.preventDefault();
        e.stopPropagation();

        const fileClass = 'file-upload__file',
            fileEl = e.target.classList.contains(fileClass) ? e.target : e.target.closest(`.${fileClass}`),
            id = Number(fileEl.dataset.id);

        this.fileList = this.fileList.filter((fileItem) => fileItem.id !== id);

        this.renderUploader();
        this.setInputFiles();
    }

    renderInput () {
        const fileInput = document.createElement('input');

        fileInput.style.display = 'none';
        fileInput.type = 'file';
        fileInput.name = 'files[]';
        fileInput.multiple = true;

        this.el.appendChild(fileInput);
        this.input = fileInput;
    }

    showFileDialog () {
        this.input.click();
    }

    validateFile (file) {
        let success = true;

        // Validate file size
        if (file.size > this.options.maxFileSize * 1024 * 1024) {
            this.handleValidationError(file, `${i18n.file_too_big}. ${i18n.max_file_size}: ${this.options.maxFileSize}MB`);
            success = false;
        }

        // Validate file type
        if (!this.options.fileTypeRegexp.test(file.type)) {
            this.handleValidationError(file, `${i18n.wrong_file_type}. ${i18n.correct_file_types}: ${this.options.fileTypeText}`);
            success = false;
        }

        return success;
    }

    handleValidationError (file, error) {
        console.error(error, file);

        if (!this.errors.some((el) => el.filename === file.name)) {
            this.errors.push({
                filename: file.name,
                error: error
            });
        }

        this.renderUploader();
    }

    uploadFiles (files) {
        files?.forEach((file) => {
            if (!this.validateFile(file)) {
                return;
            }

            this.uploadFile(file);
        });
    }

    uploadFile (file) {
        if (this.fileList.some((el) => el.name === file.name && el.size === file.size)) {
            return;
        }
        if (this.fileList.length >= this.options.maxFileNum) {
            this.handleValidationError(file, `${i18n.files_too_much}. ${i18n.max_files_num}: ${this.options.maxFileNum}`);

            return;
        }

        this.fileList.push({
            id: this.fileList.length + 1,
            // Loading: true,
            name: file.name,
            size: file.size,
            file: file
        });
        this.renderUploader();

        this.options.customHandleUploadFile(file);

        this.setInputFiles();

        // Const url = this.options.customHandleUploadFile(file);

        // This.fileList = this.fileList.map(el => {
        //     If (el.name === file.name) {
        //         El.loading = !url;
        //         El.url = url;
        //     }

        //     Return el;
        // });
        // This.renderUploader();
    }

    renderUploader () {
        const uploadErrorsEl = this.el.parentNode.querySelector('.js-file-upload-errors'),
            uploadFilesEl = this.el.parentNode.querySelector('.js-file-upload-files');

        // Set el state clas
        if (this.fileList.length > 0) {
            this.el.classList.remove('-empty');
        } else {
            this.el.classList.add('-empty');
        }

        // Clear current info
        uploadErrorsEl.innerHTML = '';
        uploadFilesEl.innerHTML = '';

        // Add new infos
        this.errors.forEach((err, index) => {
            const errorEl = document.createElement('li');

            errorEl.classList.add('file-upload__error');
            errorEl.innerText = `${err.filename} - ${err.error}`;

            uploadErrorsEl?.appendChild(errorEl);

            setTimeout(() => {
                delete this.errors[index];
                errorEl.remove();
            }, 20 * 1000);
        });

        this.fileList.forEach((fileItem) => {
            const fileEl = document.createElement('div');

            fileEl.classList.add('file-upload__file');
            fileEl.dataset.id = fileItem.id;
            fileEl.onclick = this.onFileClick.bind(this);

            const fileSizeText = this.displayFileSize(fileItem.size);

            fileEl.innerHTML =
                `<p class="file-upload__file-name">
                    ${fileItem.name}                
                    <span class="file-upload__file-size">
                        (${fileSizeText})
                    </span>
                </p>`;

            if (fileItem.loading) {
                fileEl.classList.add('-loading');
            }

            // Render image preview
            if (this.options.renderPrev) {
                this.getFileImageData(fileItem).then((imageData) => {
                    if (!imageData) {
                        return;
                    }

                    const imgElWrapper = document.createElement('div');

                    imgElWrapper.classList.add('file-upload__img-wrapper');

                    imgElWrapper.innerHTML =
                        `<img src="${imageData}"class="file-upload__img" />`;

                    fileEl.append(imgElWrapper);
                });
            }

            uploadFilesEl?.prepend(fileEl);
        });
    }

    setInputFiles () {
        const dataTransfer = new DataTransfer();

        this.fileList.forEach((fileItem) => dataTransfer.items.add(fileItem.file));
        this.input.files = dataTransfer.files;
    }

    resetUploader () {
        this.fileList = [];
        this.errors = [];
        this.renderUploader();
    }

    // Optimize procees - don't read image data every time
    getFileImageData (fileItem) {
        return new Promise((resolve, reject) => {
            if (!fileItem.imageData) {
                this.readImageData(fileItem.file).then((imageData) => {
                    fileItem.imageData = imageData;
                    resolve(imageData);
                });
            } else {
                resolve(fileItem.imageData);
            }
        });
    }

    // Helpers
    displayFileSize (size) {
        let text = '';

        if (size < 1000) {
            text = `${size} B`;
        } else if (size < 1000000) {
            const rndSize = Math.round(size / 10) / 100;

            text = `${rndSize} kB`;
        } else {
            const rndSize = Math.round(size / 10000) / 100;

            text = `${rndSize} MB`;
        }

        return text;
    }


    readImageData (file) {
        if (!file instanceof File) {
            return;
        }
        if (!(/image/).test(file.type)) {
            return;
        }

        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;

            reader.readAsDataURL(file);
        });
    }
}
