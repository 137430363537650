/**
 * Init
 *
 * @module FC_Investments
 */
export default function makeFlexibleInvestments () {
    makeFlexibleInvestmentsCityLogic();
    makeInvestmentsExpandLogic();
}

/**
 * Funkcja odpowiada za logikę przełączania widocznej zakładki z inwestycjami
 * po wybraniu konkretnego miasta z wybierajki.
 *
 * @memberof module:FC_Investments
 * @version 1.0.0
 */
function makeFlexibleInvestmentsCityLogic () {
    const $investmentsTypeSelect = $('#js-investments-city-selector');

    if (0 < $investmentsTypeSelect.length) {
        $investmentsTypeSelect.on('select2:select', function () {
            $('#pills-investments-tab a[href="#pills-' + $(this).val() + '"]').tab('show');    // eslint-disable-line
        });
    }
}

/**
 * Funkcja odpowiada za rozwijanie i zwijanie collapse z inwestycjami.
 *
 * @memberof module:FC_Investments
 * @version 1.0.0
 */
function makeInvestmentsExpandLogic () {
    const $collapse = $('.js-fc-investments-collapse');

    if (0 < $collapse.length) {
        $collapse.on('show.bs.collapse', function () {
            const collapseID = $(this).attr('id');

            $('.js-fc-investments-collapse-link').each(function () {
                const linkHyperlink = $(this).attr('href');

                if (linkHyperlink.replace('#', '') === collapseID) {
                    $(this).html($(this).data('text-hide'));
                }
            });
        });

        $collapse.on('hide.bs.collapse', function () {
            const collapseID = $(this).attr('id');

            $('.js-fc-investments-collapse-link').each(function () {
                const linkHyperlink = $(this).attr('href');

                if (linkHyperlink.replace('#', '') === collapseID) {
                    $(this).html($(this).data('text-show'));
                }
            });
        });
    }
}
