/**
 * Init
 *
 * @module IncydentBox
 */
export default function makeMobileAccidentBox () {
    makeBox();
}

/**
 * Funkcja odpowiada za wyświetlenie lub schowanie box'a dla mobile z info o zgłoszeniu awarii.
 *
 * @memberof module:IncydentBox
 * @version 1.0.0
 */
function makeBox () {
    const $triggerMobileAccidentButton = $('.js-trigger-mobile-accident-box'),
        $mobileAccidentBox = $('#js-mobile-accident-box');

    $triggerMobileAccidentButton.on('click', (event) => {
        event.preventDefault();

        if ($mobileAccidentBox.hasClass('is-visible')) {
            $mobileAccidentBox.removeClass('is-visible');
        } else {
            $mobileAccidentBox.addClass('is-visible');
        }
    });
}
