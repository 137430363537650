import Swal from 'sweetalert2';
/* global ajax */
let globalOffset = 0;

/**
 * [...]
 */
export default function ajaxGetMoreNews () {
    const $section = $('#js-news-section'),
        $newsItems = $section.find('#js-news-items'),
        $newsShowMoreButton = $section.find('#js-news-show-more');

    if (0 < $newsItems.length && 0 < $newsShowMoreButton.length) {
        $newsShowMoreButton.on('click', (event) => {
            event.preventDefault();

            $.ajax({
                url: ajax.url,
                type: 'POST',
                data: {
                    action: 'ajax_get_more_news',
                    security: ajax.nonce,
                    data: {
                        offset: globalOffset
                    }
                },
                beforeSend: function () {
                    $section.addClass('is-locked');
                },
                success: function (rawResponse) {
                    const response = rawResponse.data;

                    $newsItems.append(response.html);
                    globalOffset = response.offset;

                    if (response.has_more === false) {
                        $newsShowMoreButton.hide();
                    }

                    $section.removeClass('is-locked');
                },
                error: function (xhr, error) {
                    console.error(error);

                    const response = JSON.parse(xhr.responseText);

                    Swal.fire(
                        'Błąd',
                        response.data.message,
                        'error'
                    );
                }
            });
        });
    }
}
