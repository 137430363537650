/**
 * Funkcja zarządza logiką pływających kółek informacyjnych.
 *
 * @module Floating_Circles
 * @version 1.0.0
 */
export default function makeFloatingCirclesLogic () {
    const $floatingCircle = $('.js-floating-circle'),
        $floatingCircleBox = $('.js-floating-circle-box'),
        $floatingCirclesOverflow = $('#js-floating-circles-overflow');

    // Logika klikania na kółka
    if (0 < $floatingCircle.length && 0 < $floatingCircleBox.length) {
        $floatingCircle.on('click', function () {
            const $this = $(this),
                tooltipModalId = $this.data('tooltip-modal-id');

            if (1200 <= $(window).outerWidth()) {
                // Desktop
                $floatingCircle.removeClass('is-active');
                $this.addClass('is-active');

                $floatingCircleBox.each(function () {
                    if (tooltipModalId === $(this).data('tooltip-modal-id')) {
                        $(this).addClass('is-active');
                        $floatingCirclesOverflow.addClass('is-visible');
                    } else {
                        $(this).removeClass('is-active');
                    }
                });
            } else {
                // Mobile
                $(`#modal-${tooltipModalId}`).modal('show');
            }
        });
    }

    // Logika klikania na tło
    if (0 < $floatingCirclesOverflow.length) {
        $floatingCirclesOverflow.on('click', function () {
            $(this).removeClass('is-visible');
            $floatingCircle.removeClass('is-active');
            $floatingCircleBox.removeClass('is-active');
        });
    }
}
