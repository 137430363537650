import 'popper.js';
import 'bootstrap';
import 'air-datepicker';
import 'jquery-mask-plugin';
import 'leaflet';
import 'leaflet.markercluster';
import 'lightbox2';
import 'slick-carousel';
import 'select2';
import 'dragscroll';
import Swal from 'sweetalert2';

import ajaxGetAccidents from './modules/ajax/get-accidents';
import ajaxGetAuctions from './modules/ajax/get-auctions';
import ajaxGetInvestments from './modules/ajax/get-investments';
import ajaxGetMoreNews from './modules/ajax/get-more-news';
import ajaxGetSearchResults from './modules/ajax/get-search-results';

import makeA11Y from './modules/a11y';
import makeAccident from './modules/accident';
import makeAccidents from './modules/accidents';
import makeAuctions from './modules/auctions';
import makeCareerLogic from './modules/career';
import makeContactLogic from './modules/contact';
import makeCookies from './modules/cookie-info';
import makeDesigners from './modules/designers';
import makeFAQ from './modules/faq';
import makeFlexibleFiles from './modules/fc-files';
import makeFlexibleInvestments from './modules/fc-investments';
import makeFloatingCirclesLogic from './modules/floating-circles';
import makeForm from './modules/form';
import makeForms from './modules/forms';
import makeHeaderLogic from './modules/header';
import makeInputs from './modules/inputs';
import makeInvestmentLogic from './modules/investment';
import makeInvestmentsLogic from './modules/investments';
import makeMobileAccidentBox from './modules/mobile-accident-box';
import makeNewsLogic from './modules/news';
import makeNumberCounters from './modules/number-counters';
import makeOnlineForms from './modules/online-form';
import makePricing from './modules/pricing';
import makeSliders from './modules/sliders';
import makeSlidingNavigationLogic from './modules/sliding-navigation';
import makeSmoothScrollLogic from './modules/smooth-scroll';
import makeWaterMap from './modules/water-map';

window.Swal = Swal;

const documentReady = () => {
    makeA11Y();
    makeAccident();
    makeAccidents();
    makeAuctions();
    makeCareerLogic();
    makeContactLogic();
    makeCookies();
    makeDesigners();
    makeFAQ();
    makeFlexibleFiles();
    makeFlexibleInvestments();
    makeFloatingCirclesLogic();
    makeForm();
    makeForms();
    makeHeaderLogic();
    makeInputs();
    makeInvestmentLogic();
    makeInvestmentsLogic();
    makeMobileAccidentBox();
    makeNewsLogic();
    makeNumberCounters();
    makeOnlineForms();
    makePricing();
    makeSliders();
    makeSlidingNavigationLogic();
    makeSmoothScrollLogic();
    makeWaterMap();

    // AJAX
    ajaxGetAccidents();
    ajaxGetAuctions();
    ajaxGetInvestments();
    ajaxGetMoreNews();
    ajaxGetSearchResults();

    // Bootstrap Tooltip Init
    $('[data-toggle="tooltip"]').tooltip();
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)   // eslint-disable-line
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
