import Swal from 'sweetalert2';
/* global ajax */

/**
 * Init
 */
export default function ajaxGetSearchResults () {
    makeSearchLogic();
}

const $headerOverflow = $('#js-header-overflow'),
    $headerSearchButton = $('#js-header-search-button'),
    $headerSearchCloseButton = $('#js-header-search-close-button'),
    $headerSearchInput = $('#js-header-search-input'),
    $headerSearchInputBox = $('#js-header-search-input-wrapper'),
    $headerSearchResultsBox = $('#js-header-search-results-box'),
    $headerSearchResultsLoader = $('#js-header-search-results-loader'),
    $headerSearchResults = $('#js-header-search-results');

/**
 * Funkcja odpowiada za logikę wyszukwiarki.
 *
 * @version 1.0.0
 */
function makeSearchLogic () {
    $headerSearchButton.on('click', () => {
        $headerSearchInputBox.addClass('is-active');
        $headerOverflow.addClass('is-visible');
        $headerSearchInput.trigger('focus');
    });

    $headerSearchCloseButton.on('click', () => {
        $headerSearchInputBox.removeClass('is-active');
        $headerOverflow.removeClass('is-visible');
        $headerSearchResultsBox.removeClass('is-visible');
        $headerSearchInput.val('');
    });

    let searchTimeout = null;

    $headerSearchInput.on('input', function () {
        const $this = $(this),
            inputValue = $this.val(),
            inputValueLength = inputValue.length,
            searchTimeoutDelay = 1500;

        if (0 < inputValueLength) {
            $headerSearchResultsBox.addClass('is-visible');
            $headerSearchResultsLoader.addClass('is-visible');
            $headerSearchResults.html('');
        } else {
            $headerSearchResultsBox.removeClass('is-visible');
        }

        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            getSearchResults(inputValue);
        }, searchTimeoutDelay);
    });
}

/**
 * [...]
 *
 * @param {string} searchValue - wartość, która ma zostać wyszukana
 */
function getSearchResults (searchValue) {
    $.ajax({
        url: ajax.url,
        type: 'POST',
        data: {
            action: 'ajax_get_search_results',
            security: ajax.nonce,
            data: {
                searchValue: searchValue
            }
        },
        beforeSend () {
            // Type something...
        },
        success (rawResponse) {
            const response = rawResponse.data;

            $headerSearchResultsLoader.removeClass('is-visible');
            $headerSearchResults.html(response.html);
        },
        error (xhr, error) {
            console.error(error);

            const response = JSON.parse(xhr.responseText);

            Swal.fire(
                'Błąd',
                response.data.message,
                'error'
            );
        }
    });
}
