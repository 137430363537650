import Swal from 'sweetalert2';
import {refreshMapPoints} from './../investments';
import {refreshNavigation} from './../sliding-navigation';

let offset = 0,
    nextOffsetBigger = true;
const $section = $('#js-section-investments');

/* global ajax */

/**
 * Init.
 *
 * @version 1.0.0
 */
export default function ajaxGetInvestments () {
    const $loadMoreInvestmentsButton = $section.find('#js-load-more-investments'),
        $form = $section.find('#js-investment-form'),
        $switchCategoryButton = $section.find('.js-switch-investment-category-button'),
        $switchCategorySelect = $section.find('#js-investment-category-selector'),
        $inputCategory = $section.find('#js-investments-input-category'),
        $inputNumberOfPosts = $section.find('#js-investments-input-number-of-posts');

    // ======================
    // = Triggery Event'ów
    // ======================

    // Zmiana kategorii - desktop
    $switchCategoryButton.on('click', function (event) {
        event.preventDefault();

        const category = $(this).data('category');

        $inputCategory.val(category);
        $inputNumberOfPosts.val(11); // eslint-disable-line no-magic-numbers
        $form.trigger('submit');
        nextOffsetBigger = true;
    });

    // Zmiana kategorii - mobile
    $switchCategorySelect.on('select2:select', function () {
        const category = $(this).val();

        $inputCategory.val(category);
        $inputNumberOfPosts.val(11); // eslint-disable-line no-magic-numbers
        $form.trigger('submit');
        nextOffsetBigger = true;
    });

    // Doładowanie więcej wyników
    $loadMoreInvestmentsButton.on('click', (event) => {
        event.preventDefault();

        $inputNumberOfPosts.val(6); // eslint-disable-line no-magic-numbers
        loadInvestments(false);
        nextOffsetBigger = false;
    });

    // Trigger dla wysłania formularza
    $form.on('submit', (event) => {
        event.preventDefault();

        $inputNumberOfPosts.val(11); // eslint-disable-line no-magic-numbers
        loadInvestments(true);
        nextOffsetBigger = true;
    });
}

/**
 * Funkcja pobierająca inwestycje.
 *
 * @param {boolean} makeClear - flaga, dzięki której wiadomo, czy należy wyczyścić aktualną paginację/numerację/offset.
 */
function loadInvestments (makeClear) {
    const $loadMoreInvestmentsButton = $section.find('#js-load-more-investments'),
        $form = $section.find('#js-investment-form'),
        $inputMapPoints = $section.find('#js-investments-input-map-points'),
        formData = $form.serializeArray().reduce((obj, item) => {
            obj[item.name] = item.value;

            return obj;
        }, {}),
        $itemsContainer = $section.find('#js-investments-items');

    if (0 < $itemsContainer.length) {
        $.ajax({
            url: ajax.url,
            type: 'POST',
            data: {
                action: 'ajax_get_investments',
                security: ajax.nonce,
                data: {
                    offset: offset,
                    category: formData.category,
                    numberOfPosts: formData.number_of_posts,
                    searchAddress: formData.search_address,
                    makeClear: makeClear,
                    nextOffsetBigger: nextOffsetBigger
                }
            },
            beforeSend () {
                $section.addClass('is-loading');
            },
            success (rawResponse) {
                const response = rawResponse.data,
                    $numberOfInvestmentsSpan = $('#js-number-of-investments'),
                    $numberOfPlannedWorkSpan = $('#js-number-of-planned-works'),
                    $numberOfImpedimentsSpan = $('#js-number-of-impediments');

                if (makeClear === true) {
                    $itemsContainer.html(response.html);
                } else {
                    $itemsContainer.append(response.html);
                }

                // Jeżeli nie ma więcej wyników do doładowania, to ukrywamy link "Pokaż więcej"
                if (response.has_more === true) {
                    $loadMoreInvestmentsButton.show();
                } else {
                    $loadMoreInvestmentsButton.hide();
                }

                // Jeżeli wyszukiwaliśmy to pokazujemy ilość wyszukanych wyników
                if (typeof response.number_of_results === 'object') {
                    if (typeof response.number_of_results.investment === 'number') {
                        $numberOfInvestmentsSpan.html(`(${response.number_of_results.investment})`);
                    }

                    if (typeof response.number_of_results.planned_work === 'number') {
                        $numberOfPlannedWorkSpan.html(`(${response.number_of_results.planned_work})`);
                    }

                    if (typeof response.number_of_results.impediment === 'number') {
                        $numberOfImpedimentsSpan.html(`(${response.number_of_results.impediment})`);
                    }
                } else {
                    $numberOfInvestmentsSpan.html('');
                    $numberOfPlannedWorkSpan.html('');
                    $numberOfImpedimentsSpan.html('');
                }

                // Nadpisanie offset'u
                offset = response.offset; // eslint-disable-line prefer-destructuring

                // Nadpisanie wartości dla punktów na mapie jeżeli przyszły nowe punkty
                if (response.map_points !== null) {
                    $inputMapPoints.val(response.map_points);
                }

                refreshMapPoints();
                refreshNavigation();
                $section.removeClass('is-loading');
            },
            error (xhr, error) {
                console.error(error);

                const response = JSON.parse(xhr.responseText);

                Swal.fire(
                    'Błąd',
                    response.data.message,
                    'error'
                );
            }
        });
    }
}

/**
 * Funkcja zwracająca kod HTML z belką jednej inwestycji.
 *
 * @param {int} investmentId - id inwestycji, która ma zostać zwrócona.
 * @return {*} response - [...]
 * @version 1.0.0
 */
export function getInvestment (investmentId) {
    let response = null;

    $.ajax({
        url: ajax.url,
        type: 'POST',
        async: false,
        data: {
            action: 'ajax_get_investment',
            security: ajax.nonce,
            data: {
                investmentId: investmentId
            }
        },
        success (rawResponse) {
            response = rawResponse.data;
        },
        error (xhr, error) {
            console.error(error);

            response = JSON.parse(xhr.responseText);

            Swal.fire(
                'Błąd',
                response.data.message,
                'error'
            );
        }
    });

    return response;
}
