/**
 * Init
 *
 * @module Mapa_Wody
 */
export default function makeWaterMap () {
    makeWaterMapLogic();
    makeWaterMapDetailedLogic();
    makeMobileSelectLogic();
}

/**
 * Funkcja odpowiada za:
 * - wyświetlanie box'u z info nt. danego regionu
 * - zarządza podświetleniem aktualnie aktywnego regionu
 *
 * @memberof module:Mapa_Wody
 */
function makeWaterMapLogic () {
    const $mapPath = $('.js-water-map-path');

    $mapPath.on('click', function (event) {
        const $this = $(this),
            selectedRegion = $this.attr('data-region'),
            $mapSVG = $('#js-water-map-svg'),
            $mapBox = $('.js-water-map-region-box'),
            clickLocation = {
                coordX: event.pageX - $mapSVG.offset().left + 10,
                coordY: event.pageY - $mapSVG.offset().top + 10
            };

        // Zmieniamy podświetlenie mapy
        $mapSVG.find('path').each(function () {
            if ($(this).attr('data-region') === selectedRegion) {
                $(this).addClass('is-active');
            } else {
                $(this).removeClass('is-active');
            }
        });

        // Zmieniamy aktywny box
        $mapBox.removeClass('is-visible');
        $mapBox.each(function () {
            if ($(this).attr('data-region') === selectedRegion) {
                $(this).css('top', clickLocation.coordY)
                    .css('left', clickLocation.coordX);
                $(this).addClass('is-visible');
            }
        });

        // Zamykanie box'a
        $('.js-water-map-region-box-close').on('click', () => {
            $mapBox.removeClass('is-visible');
            $mapSVG.find('path').removeClass('is-active');
        });
    });
}

/**
 * Funkcja odpowiada za logikę mapy szczegółowej.
 * TODO: W przyszłości przepisać to na OpenStreetMap - aktualne rozwiązanie jest tak trochę meh :E
 *
 * @memberof module:Mapa_Wody
 * @version 1.0.0
 */
function makeWaterMapDetailedLogic () {
    const $mapPath = $('.js-water-map-path-detailed');

    $mapPath.on('click', function (event) {
        const $this = $(this),
            selectedRegion = $this.attr('data-region'),
            $mapSVG = $('#js-water-map-svg-detailed'),
            $mapBox = $('.js-water-map-region-box-detailed'),
            clickLocation = {
                coordX: event.pageX - $mapSVG.offset().left + 10,
                coordY: event.pageY - $mapSVG.offset().top + 10
            };

        // Zmieniamy podświetlenie mapy
        $mapSVG.find('path').each(function () {
            if ($(this).attr('data-region') === selectedRegion) {
                $(this).addClass('is-active');
            } else {
                $(this).removeClass('is-active');
            }
        });

        // Zmieniamy aktywny box
        $mapBox.removeClass('is-visible');
        $mapBox.each(function () {
            if ($(this).attr('data-region') === selectedRegion) {
                $(this).css('top', clickLocation.coordY)
                    .css('left', clickLocation.coordX);
                $(this).addClass('is-visible');
            }
        });

        // Zamykanie box'a
        $('.js-water-map-region-box-close').on('click', () => {
            $mapBox.removeClass('is-visible');
            $mapSVG.find('path').removeClass('is-active');
        });
    });
}

/**
 * Funkcja odpowiada za przełączanie kart z danymi w zależności od wybranej mapy.
 *
 * @memberof module:Mapa_Wody
 * @version 1.0.0
 */
function makeMobileSelectLogic () {
    const $mapTypeSelect = $('#js-water-map-pill-selector');

    if (0 < $mapTypeSelect.length) {
        $mapTypeSelect.on('select2:select', function () {
            $('#pills-water-map-tab a[href="#pills-' + $(this).val() + '"]').tab('show');    // eslint-disable-line
        });
    }
}
