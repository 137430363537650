import Swal from 'sweetalert2';
/* global ajax */
let globalOffset = 0;

/**
 * Init dla funkcji zarządzających przetargami.
 */
export default function ajaxGetAuctions () {
    const $auctionsSearchForm = $('#js-auctions-search-form'),
        $auctionsShowMoreButton = $('#js-auctions-show-more');

    // ======================
    // = Triggery Event'ów
    // ======================

    // Wyszukiwanie konkretnego przetargu
    if (0 < $auctionsSearchForm.length) {
        $auctionsSearchForm.on('submit', (event) => {
            event.preventDefault();

            const $searchInput = $auctionsSearchForm.find('#js-auctions-search-input'),
                searchValue = $searchInput.val();

            getAuctions(searchValue);
        });
    }

    // Ładowanie większej ilości wyników
    if (0 < $auctionsShowMoreButton.length) {
        $auctionsShowMoreButton.on('click', (event) => {
            event.preventDefault();

            getAuctions();
        });
    }
}

/**
 * [...]
 *
 * @param {string} searchValue - [...]
 */
function getAuctions (searchValue) {
    const $section = $('#js-auctions-section'),
        $auctionsItems = $section.find('#js-auctions-items'),
        $auctionsShowMoreButton = $section.find('#js-auctions-show-more');

    $.ajax({
        url: ajax.url,
        type: 'POST',
        data: {
            action: 'ajax_get_auctions',
            security: ajax.nonce,
            data: {
                offset: globalOffset,
                searchValue: searchValue ? searchValue : null
            }
        },
        beforeSend: function () {
            $section.addClass('is-locked');
        },
        success: function (rawResponse) {
            const response = rawResponse.data;

            if (response.override === true) {
                $auctionsItems.html(response.html);
            } else {
                $auctionsItems.append(response.html);
            }

            globalOffset = response.offset;

            if (response.has_more === false) {
                $auctionsShowMoreButton.hide();
            } else {
                $auctionsShowMoreButton.show();
            }

            $section.removeClass('is-locked');
        },
        error: function (xhr, error) {
            console.error(error);

            const response = JSON.parse(xhr.responseText);

            Swal.fire(
                'Błąd',
                response.data.message,
                'error'
            );
        }
    });
}
