/**
 * Funkcja zarządza logiką header'a.
 *
 * @module Header
 */
export default function makeHeaderLogic () {
    fitBodySpaceToHeader();
    makeMegaMenuLogic();
    makeAccidentBoxLogic();
    makeMobileMenuLogic();

    $(window).on('scroll', () => {
        makeNavigationScrolled();
    });

    $(window).on('resize', () => {
        fitBodySpaceToHeader();
    });
}

/**
 * Funkcja oblicza aktualną wysokość całego pływającego header wraz z menu,
 * a następnie obniża BODY o tyle, aby nagłówek nie przykrywał treści ze strony.
 *
 * @memberof module:Header
 * @version 1.0.0
 */
function fitBodySpaceToHeader () {
    const $alertNotice = $('#js-header-alert-notice'),
        $menuBar = $('#js-header-menubar');

    let finalBodyspacer = 0;

    if (0 < $menuBar.length) {
        finalBodyspacer += $menuBar.height();
    }

    if (0 < $alertNotice.length) {
        finalBodyspacer += $alertNotice.height();
    }

    $('body').css('padding-top', finalBodyspacer);
}

/**
 * Funkcja odpowiada za triggery dla logiki MEGA Menu.
 *
 * @memberof module:Header
 * @version 1.0.0
 */
function makeMegaMenuLogic () {
    const $headerOverflow = $('#js-header-overflow'),
        $megamenu = $('.js-header-megamenu'),
        $megamenuBox = $('.js-header-megamenu-box');

    /**
     * Podpinanie event'ów dla otwierania megamenu.
     */
    if (0 < $megamenu.length) {
        $megamenu.on('mouseenter', function () {
            openMegamenu($(this));
        });
    }

    if (0 < $megamenuBox.length) {
        $megamenuBox.on('mouseenter', function () {
            openMegamenu($(this));
        });
    }

    /**
     * Podpinanie event'ów dla zamykania megamenu.
     */
    $megamenu.on('mouseleave', () => {
        closeMegamenus();
    });

    $megamenuBox.on('mouseleave', () => {
        closeMegamenus();
    });

    /**
     * Funkcja otwiera wybrane megamenu jednocześnie synchronizując
     * ze sobą 'hover' na menu item lub megamenu.
     *
     * @memberof module:Header
     * @param {object} $this - obiekt danego elementu megamenu.
     * @version 1.0.0
     */
    function openMegamenu ($this) {
        const dataTooltipId = $this.data('megamenu-id');

        $megamenu.removeClass('is-active');

        if ($this.hasClass('js-header-megamenu')) {
            $this.addClass('is-active');
            $megamenuBox.each(function () {
                if (dataTooltipId === $(this).data('megamenu-id')) {
                    $(this).addClass('is-visible');
                    $headerOverflow.addClass('is-visible');
                } else {
                    $(this).removeClass('is-visible');
                }
            });
        } else {
            $this.addClass('is-visible');
            $megamenu.each(function () {
                if (dataTooltipId === $(this).data('megamenu-id')) {
                    $(this).addClass('is-active');
                    $headerOverflow.addClass('is-visible');
                } else {
                    $(this).removeClass('is-active');
                }
            });
        }
    }

    /**
     * Funkcja zamyka wszystkie megamenu.
     *
     * @memberof module:Header
     * @version 1.0.0
     */
    function closeMegamenus () {
        $megamenu.removeClass('is-active');
        $megamenuBox.removeClass('is-visible');
        $headerOverflow.removeClass('is-visible');
    }
}

/**
 * Funkcja odpowiada za otwieranie box'a "Zgłoś awarię".
 *
 * @memberof module:Header
 * @version 1.0.0
 */
function makeAccidentBoxLogic () {
    const $headerAccidentInfoWrapper = $('#js-header-accident-info-wrapper'),
        $headerAccidentInfoButton = $headerAccidentInfoWrapper.find('#js-header-accident-info-button'),
        $headerOverflow = $('#js-header-overflow');

    $headerAccidentInfoButton.on('click', (event) => {
        event.preventDefault();

        if ($headerAccidentInfoWrapper.hasClass('is-active')) {
            $headerAccidentInfoWrapper.removeClass('is-active');
            $headerOverflow.removeClass('is-visible');
        } else {
            $headerAccidentInfoWrapper.addClass('is-active');
            $headerOverflow.addClass('is-visible');
        }
    });

    // Zamykanie "Zgłoś awarię" na kliknięcie w tło.
    $headerOverflow.on('click', () => {
        $headerAccidentInfoWrapper.removeClass('is-active');
        $headerOverflow.removeClass('is-visible');
    });
}

/**
 * Funkcja zarządza logiką mobilnego rozsuwanego menu.
 *
 * @memberof module:Header
 * @version 1.0.0
 */
function makeMobileMenuLogic () {
    const $headerMobileMenuButton = $('#js-header-floating-menu-bar-button'),
        $headerMobileMenu = $('#js-header-floating-menu'),
        $headerAlertNotice = $('#js-header-alert-notice');

    $headerMobileMenuButton.on('click', function (event) {
        event.preventDefault();

        const $this = $(this);

        if ($this.hasClass('is-opened') === false) {
            $('body').addClass('modal-open');
            $this.addClass('is-opened');
            $headerMobileMenu.addClass('is-opened');
            $headerAlertNotice.addClass('is-forced-hidden');
        } else {
            $('body').removeClass('modal-open');
            $this.removeClass('is-opened');
            $headerMobileMenu.removeClass('is-opened');
            $headerAlertNotice.removeClass('is-forced-hidden');
        }
    });
}

let position = $(window).scrollTop();

/**
 * Funkcja zarządza zmianą paska nawigacyjnego.
 *
 * @memberof module:Header
 * @version 1.3.0
 */
function makeNavigationScrolled () {
    const $menuBar = $('#js-header-menubar'),
        scroll = $(window).scrollTop(),
        $headerSearchInputBox = $('#js-header-search-input-wrapper'),
        $headerSearchInput = $('#js-header-search-input'),
        $headerOverflow = $('#js-header-overflow'),
        $headerSearchResultsBox = $('#js-header-search-results-box'),
        $headerAccidentInfoWrapper = $('#js-header-accident-info-wrapper'),
        $megamenuBox = $('.js-header-megamenu-box'),
        scrollStartBreakpoint = 32,
        scrollDirectionStartBreakpoint = 128;

    // Efekty na 0 < scroll
    if (scrollStartBreakpoint < $(document).scrollTop()) {
        $headerSearchInputBox.removeClass('is-active');
        $headerOverflow.removeClass('is-visible');
        $headerSearchResultsBox.removeClass('is-visible');
        $headerSearchInput.val('');
        $headerAccidentInfoWrapper.removeClass('is-active');
        $megamenuBox.removeClass('is-visible');
    }

    // Efekty zależne od kierunku scroll'a
    if (position < scroll && scrollDirectionStartBreakpoint < $(document).scrollTop()) {
        $menuBar.addClass('is-scrolling');
    } else {
        $menuBar.removeClass('is-scrolling');
    }
    position = scroll;
}
